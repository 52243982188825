import React, { useEffect } from 'react';

import { IconButton } from '@mui/material';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import { updateUserChatConfig } from '../../../actions/conversations';
import { OpenInNew } from '@mui/icons-material';

/**
 * Update chatWin in localstorage and dispatchEvent
 *
 * @param {Object} data - data to set in chatWin
 * @param {Number} timeout - time for the promise to resolve
 */
const updateChatWin = (channel, timeout = 0) => new Promise((res) => {
  if (channel && channel.postMessage) {
    channel.postMessage({ close: true });
  }
  setTimeout(() => res(true), timeout);
});

let chatChannel = null;
let chatPage = null;
const ChatButton = ({ user }) => {
  const openWindow = async (fromButton) => {
    if (!chatPage || chatPage.closed) {
      await updateChatWin(chatChannel, 500);
      chatPage = window.open(
        '/conversations',
        'chat',
        'location=0,toolbar=no,status=no,menubar=no,scrollbars=no,' +
        'resizable=no,width=1250, height=770, visible=none'
      );
    } else if (chatPage) {
      if (chatPage.location && fromButton) {
        chatPage.location.reload(true);
      }
      chatPage.focus();
    }
  };

  const onStorageChange = () => {
    const chatWin = (sessionStorage.getItem('chatWin') || '').parse() || {};
    if (chatWin && chatWin.focus) {
      const { focus, ...rest } = chatWin;
      updateUserChatConfig(user._id, {
        lastSelected: {
          smsType: rest.selectType,
          selectedId: rest.selectId
        }
      });
      if (!chatWin.inWindow) {
        setTimeout(() => {
          openWindow();
        }, dayjs().diff(focus * 1000, 'millisecond'));
      }
      chatChannel.postMessage(rest);
      sessionStorage.removeItem('chatWin');
    }
  };

  useEffect(() => {
    chatChannel = new BroadcastChannel('chatChannel');
    window.addEventListener('storage', onStorageChange);
    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  }, []);


  return <IconButton
    style={{ color: '#ddd' }}
    onClick={() => openWindow(true)}
  >
    <OpenInNew />
  </IconButton>;
};

export default ChatButton;
