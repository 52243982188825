import api from '../api';
import {
  listMailsSST,
  listMailsClient,
} from '@lba-dev/package.local-globals/listMails';
import notifSystem from '../notifSystem';
import store from '../store';
import {
  setDialog,
  setDialogContentProp,
  setDialogContentProps
} from './dialog';
import { SAV_ORDINAIRE } from '@lba-dev/package.local-globals/cSAV';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import { D_MAR } from '@lba-dev/package.local-globals/deStatus';
import { SAV_APR, SAV_ENC } from '@lba-dev/package.local-globals/SAVStatus';
import { SAV_RECLA } from '@lba-dev/package.local-globals/natureSAV';
import { SMS_SST1 } from '@lba-dev/package.local-globals/SAVSMS';
import { RT_ARTISAN } from '@lba-dev/package.local-globals/smsRecipientType';

export const steps = {
  INFO: 0,
  MAIL: 1,
  RDV: 3,
  SST: 4,
  DEM: 5,
  CSSTMAIL: 6,
};

const getDate = (savDate) => savDate ? new Date(savDate) : 0;

const getMailByName = (
  { savInter, date, artisan },
  name,
  type,
  user
) => ({
  client: listMailsClient(savInter, date, user).find(e => e.name === name),
  sst: listMailsSST({
    savInter,
    artisan,
    date,
    user
  }).find(e => e.name === name)
}[type]);

const initProps = (savInter) => ({
  savInter,
  nature: savInter.nature !== undefined
    ? savInter.nature
    : +(!!savInter.isRegle),
  catSAV: savInter.catSAV || SAV_ORDINAIRE,
  comment: '',
  stepper: 0,
  status: savInter.status,
  mail: { name: '', text: '', subject: '', desMail: '' },
  mailSST: { name: '', text: '', subject: '', desMail: '' },
  changementSSTMail: { name: '', text: '', subject: '', desMail: '' },
  infoDescSAV: savInter.infoDescSAV || {},
  date: { savDate: getDate(savInter.date.savDate) },
  artisanSelected: {},
  attachments: [],
  attachmentsSST: [],
  sms: null
});

const resetProps = (name, savInter) => store.dispatch(
  setDialogContentProp(name)(initProps(savInter)[name])
);

export const getSmsEnvoiSAV = (savInter) => ({
  type: SMS_SST1,
  recipient: savInter.currentArtisan.name,
  number: savInter.currentArtisan.tel1,
  recipientType: RT_ARTISAN,
});

const changeSST = (savInter, date, artisan, user) => {
  const contentProps = store.getState().dialog.contentProps;
  const mailSST = getMailByName(
    { savInter: savInter, artisan, date },
    'ENVOIE DU SAV',
    'sst',
    user
  );
  const mailClient = getMailByName(
    { savInter: savInter, date },
    'Confirmation de RDV',
    'client',
    user
  );
  const sms = getSmsEnvoiSAV(savInter);
  const newContentProps = {
    ...contentProps,
    mailSST,
    mail: mailClient,
    status: SAV_ENC,
    sms,
    stepper: steps.MAIL
  };
  store.dispatch(setDialogContentProps(newContentProps));
};

const changeDate = ({ date, savInter, newSav }) => {
  const contentProps = store.getState().dialog.contentProps;
  if (
    date.savDate && (
      !newSav.date.savDate ||
      !dayjs(savInter.date.savDate).isSame(dayjs(date.savDate))
    )
  ) {
    const newContentProps = {
      ...contentProps,
      status: SAV_ENC,
      stepper: steps.INFO,
    };
    return store.dispatch(setDialogContentProps(newContentProps));
  }
  store.dispatch(setDialogContentProps(contentProps));
  return resetProps('stepper', savInter);
};

export function sendSavToMarket(inter, close = f => f) {
  api.savInterventions
    .patch(inter._id, { aDemarcher: D_MAR, status: SAV_APR })
    .then(() => {
      close();
      return notifSystem.success(
        'Réussie',
        'Intervention SAV envoyé dans le market'
      );
    })
    .catch(e => notifSystem.error(e.name, e.message));
}

const resetedContentProps = (step, savInter, resetStepper) => {
  const contentProps = store.getState().dialog.contentProps;
  let fieldsToReset = {
    [steps.MAIL]:
      ['mailSST', 'mail', 'attachments', 'attachmentsSST', 'status',
        'changementSSTMail', 'sms'],
    [steps.RDV]: ['infoDescSAV', 'date'],
    [steps.SST]: ['savInter', 'changementSSTMail'],
    [steps.CSSTMAIL]: ['changementSSTMail'],
  }[step];
  fieldsToReset = [...fieldsToReset, ...(resetStepper ? ['stepper'] : [])];
  return fieldsToReset.reduce((acc, curr) => ({
    ...acc,
    [curr]: initProps(savInter)[curr]
  }), contentProps);
};

const resetByStep = (savInter, stepper, resetStepper = false) => {
  if (stepper === steps.DEM) {
    return resetProps('stepper', savInter);
  }
  const newContentProps = resetedContentProps(stepper, savInter, resetStepper);
  store.dispatch(setDialogContentProps(newContentProps));
};

const getErrors = (step, { mail, comment, infoDescSAV, mailSST, nature }) => {
  if (!comment.trim().length && step === steps.INFO) {
    notifSystem.error('Erreur', 'Veuillez entrer un commentaire');
    return true;
  }
  if (step === steps.MAIL) {
    const arr = ['(OBJET INTER)*', '(OBJET SAV)*', '(Justification courte)*'];
    const err = arr.find(e => [mail, mailSST].find(m => m.text.includes(e)));
    if (err) {
      notifSystem.error('Erreur', `Veuillez corriger l'email (${err})`);
      return true;
    }
    return false;
  }
  if (
    [steps.RDV, steps.INFO].includes(step) &&
    !infoDescSAV.desc &&
    nature !== SAV_RECLA
  ) {
    notifSystem.error('Erreur', 'Veuillez entrer une description');
    return true;
  }
  return false;
};

const updateSav = ({
  savInter,
  catSAV, nature, status,
  date,
  infoDescSAV,
  artisanSelected
}) =>
  api.savInterventions.patch(
    savInter._id,
    {
      catSAV, nature, status,
      ...(date.savDate ? { date } : {}),
      ...(Object.keys(infoDescSAV).length ? { infoDescSAV } : {}),
      ...(Object.keys(artisanSelected).length
        ? { artisan: artisanSelected._id }
        : {}),
    });

const priseDeContactActions = ({
  savInter,
  mail, mailSST, attachments, attachmentsSST, changementSSTMail,
  status,
  comment,
  close
}) =>
  api.savInterventions
    .custom('priseDeContact')
    .post({
      savUnderscoreId: savInter._id,
      interId: savInter.interId,
      mail, mailSST, attachments, attachmentsSST, changementSSTMail,
      status,
      comment,
    })
    .then(() => close())
    .catch(() =>
      notifSystem.error('Erreur', 'Le SAV n\'a pas pu être modifié.'));

const sendSMSAction = ({ sms, savInter, date, infoDescSAV, close }) => {
  const data = {
    ...savInter,
    date: { ...savInter.date, ...date },
    infoDescSAV: { ...savInter.infoDescSAV, ...infoDescSAV }
  };
  if (sms) {
    api.savInterventions
      .custom('sendSMSAction')
      .post({ sms, data })
      .then(() => close())
      .catch(() =>
        notifSystem.error('Erreur', 'Le SAV n\'a pas pu être modifié.'));
  }
};

const validate = (elem) => {
  updateSav(elem)
    .then(() => {
      priseDeContactActions(elem);
      sendSMSAction(elem);
    })
    .catch(() =>
      notifSystem.error('Erreur', 'Le SAV n\'a pas pu être modifié'));
};

export const priseDeContact = (savInter, user) => store.dispatch(
  setDialog({
    name: 'PriseDeContactDialogSAV',
    open: true,
    hideClose: true,
    dialogProps: {
      title: 'Prise de contact',
      maxWidth: 'md',
      middle: true,
    },
    contentProps: initProps(savInter),
    actions: [
      {
        children: 'Fermer',
        hideButton: ({ stepper }) => stepper,
        color: 'secondary',
        onClick: (_, close) => close()
      },
      {
        children: 'Valider',
        hideButton: ({ stepper }) => stepper,
        color: 'primary',
        onClick: ({ stepper, ...elem }, close) => {
          const error = getErrors(stepper, elem);
          if (!error) {
            return validate({ ...elem, savInter, close });
          }
        }
      },
      {
        children: 'RESET',
        hideButton: ({ stepper }) =>
          !stepper || [steps.SST, steps.DEM, steps.CSSTMAIL].includes(stepper),
        color: 'inherit',
        onClick: ({ stepper }) => resetByStep(savInter, stepper),
      },
      {
        children: 'REVENIR',
        hideButton: ({ stepper }) => !stepper,
        color: 'secondary',
        onClick: ({ stepper }) => resetByStep(savInter, stepper, true),
      },
      {
        children: 'CONFIRMER',
        hideButton: ({ stepper }) => !stepper,
        color: 'primary',
        onClick: ({ stepper, date, ...data }, close) => {
          const error = getErrors(stepper, data);
          if (error) {
            return;
          }
          if (stepper === steps.RDV) {
            return changeDate({ savInter, date, newSav: data.savInter });
          }
          if (stepper === steps.CSSTMAIL) {
            return store.dispatch(setDialogContentProp('stepper')(steps.SST));
          }
          if (stepper === steps.SST) {
            return changeSST(data.savInter, date, data.artisanSelected, user);
          }
          if (stepper === steps.DEM) {
            return sendSavToMarket(savInter, close);
          }
          return resetProps('stepper', savInter);
        },
      },
    ]
  })
);

