import React from 'react';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import {
  getAliasFromPhone,
  getAlias
} from '../../utils/function';
import {
  Chip,
  Grid,
  Icon,
  Tooltip,
  Typography,
} from '@mui/material';
import { callTypes } from '@lba-dev/package.local-globals/callTypes';
import { ReadMore } from '@mui/icons-material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const directions = {
  outbound: 'sortant',
  inbound: 'entrant',
  undefined: 'entrant',
};

const styles = {
  chip: {
    margin: '0px 10px',
  }
}

export const sliceNumber = (number) =>
  number ? `${number.slice(0, 4)}...` : null


export const keyUrls = {
  inter: {
    key: 'fileUrl',
    text: (call) =>
      <span>
        {dayjs(call.record.callStart).format('L à HH:mm')}
        {
          call.record.duration > 0
            ? `- ${dayjs.utc(call.record.duration * 1000).format('HH:mm:ss')}`
            : ' '
        }
        {call.record.callerIdNumber
          ? <Chip
            sx={styles.chip}
            icon={
              <LocalPhoneIcon fontSize='small' />
            }
            label={ sliceNumber(call.record.callerIdNumber)}
            variant="outlined" />
          : ''}
        {call.service} |
        {
          !call.record.userId
            ? getAliasFromPhone(call.record.agent.replace(/^0033/, '0'), false)
            : getAlias(call.record.userId)
        } | ${directions[call.record.direction] || ''}
      </span>
  },
  candidat: {
    key: 'fileUrl',
    text: (call) =>
      `${dayjs(call.record.callStart).format('DD/MM/YY à HH:mm')} ${
        call.duration > 0
          ? `- ${dayjs.utc(call.duration * 1000).format('HH:mm:ss')}`
          : ' '
      } ${
        call.record.agent
          ? ` - ${getAliasFromPhone(
            call.record.agent.replace(/^0033/, '0'),
            false
          )} a appelé le candidat`
          : ' '
      } `,
  },
  url: {
    key: 'url',
    text: (call) => (
      <Grid
        container
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
      >
        <Grid item xs={11}>
          <span>
            {`${dayjs(call.date).format('DD/MM/YY à HH:mm')}`}
          </span>
          <span
            style={{ fontSize: 12, marginInlineEnd: 'auto', paddingLeft: 10 }}
          >
            {`${call.companyName} a été contacté par ${getAlias(call.userId)}`}
          </span>
        </Grid>
        <Grid item xs={1}>
          <Tooltip
            disableInteractive
            title={`appel depuis ${
              call.interventionId ? 'intervention' : 'demande'}`}
          >
            <Icon
              style={{ marginInlineEnd: 'auto', paddingLeft: 10 }}
              fontSize="small"
              children={call.interventionId ? <ReadMore /> : 'list'}
            />
          </Tooltip>
        </Grid>
        {call.comment ? (
          <Grid item xs={12}>
            <Typography
              variant="caption"
              color="textSecondary"
              children={`Commentaire: ${call.comment}`}
            />
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    ),
  },
  urlEnregistrement: {
    key: 'urlEnregistrement',
    text: (call) => {
      const type = call.type && callTypes.find((e) => e.id === call.type);
      return (
        <Grid container justifyContent="space-between" alignContent="center">
          <Grid item>
            {`${dayjs(call.date).format('DD/MM/YY à HH:mm')} ${
              call.duration > 0
                ? `- ${dayjs.utc(call.duration * 1000).format('HH:mm:ss')}`
                : ' '
            }`}
            {call.numeroAppelant
              ? <Chip
                sx={styles.chip}
                icon={
                  <LocalPhoneIcon fontSize='small' />
                }
                label={ sliceNumber(call.numeroAppelant)}
                variant="outlined" />
              : ''}
            {type && type.icon ? (
              <Tooltip disableInteractive title={type.label}>
                <Icon
                  style={{ marginInlineEnd: 'auto', paddingLeft: 10 }}
                  fontSize="small"
                  children={type.icon}
                />
              </Tooltip>
            ) : (
              ''
            )}
          </Grid>
          {call.companyName ? (
            <Grid item>
              <Typography
                variant="caption"
                color="textSecondary"
                children={call.companyName}
              />
            </Grid>
          ) : (
            ''
          )}
          <Grid item>
            <Typography
              variant="caption"
              color="textSecondary"
              children={call.callTo ? 'CALL TO' : 'CALL BACK'}
            />
          </Grid>
          {call.played && call.datePlayed && call.userPlayed ? (
            <Grid item xs={12}>
              <Typography
                variant="caption"
                color="textSecondary"
                children={`écouter par ${getAlias(call.userPlayed, 'l\'IA')
                } - ${dayjs(
                  call.datePlayed
                ).format('DD/MM à HH[h]mm')}`}
              />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      );
    },
  },
  fileUrl: {
    key: 'fileUrl',
    text: (call) => (
      <span>
        {dayjs(call.callStart).format('DD/MM/YY à HH:mm')}
        {call.duration > 0
          ? `- ${dayjs.utc(call.duration * 1000).format('HH:mm:ss')}`
          : ' '}
        {call.agent ? ` - ${getAlias(call.userId,
          getAliasFromPhone(call.agent.replace(/^0033/, '0')))}` : ' '}
        { call.callerIdNumber
          ? <Chip
            sx={styles.chip}
            icon={
              <LocalPhoneIcon fontSize='small' />
            }
            label={ sliceNumber(call.callerIdNumber)}
            variant="outlined" />
          : ''}
      </span>
    )
  },
  ringover: {
    key: 'url',
    text: (call) =>
      <span>
        {dayjs(call.startTime).format('L à HH:mm')}
        {
          call.duration > 0
            ? `- ${dayjs.utc(call.duration * 1000).format('HH:mm:ss')}`
            : ' '
        }
        {call.toNumber
          ? <Chip
            sx={styles.chip}
            icon={
              <LocalPhoneIcon fontSize='small' />
            }
            label={ sliceNumber(call.toNumber)}
            variant="outlined" />
          : '' } Ringover |
        {
          !call.userId
            ? getAliasFromPhone(call.fromNumber.replace(/^0033/, '0'), false)
            : getAlias(call.userId)
        } | ${directions[call.direction] || ''}
      </span>


  },
  lastCalls: {
    key: 'url',
    text: (call) =>
      `${
        call.direction === 'inbound'
          ? 'L\'artisan a contacté'
          : 'L\'artisan a été contacté par'
      } ${getAlias(call.userId)} ${
        call.savId || call.interventionId
          ? `depuis ${call.isSAV ? 'le dossier sav' : 'l\'intervention'} n°${
            call.savId || call.interventionId
          }`
          : ''
      } le ${dayjs(call.date).format('L à HH:mm')}`,
  },
};
