import { EventBusy, List, AddAlert } from '@mui/icons-material';

import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import { permit } from '@lba-dev/package.local-globals/restrictions';

export default [
  {
    name: dayjs().startOf('week').format('ddd. DD MMM. YYYY'),
    bold: true,
    icon: List,
    count: true,
    link: (e) =>
      permit(e, { key: 'demandeFilters' })
        ? '/demandes/list/week'
        : `/demandes/list/week_${e._id}`,
  },
  {
    name: 'Non traitées',
    icon: List,
    count: true,
    link: (e) =>
      permit(e, { key: 'demandeFilters' })
        ? '/demandes/list/dnt'
        : `/demandes/list/dnt_${e._id}`,
  },
  {
    name: 'Demandes attribuées',
    icon: List,
    count: true,
    link: () => '/demandes/list/assignedemandes',
  },
  {
    name: 'Mes demandes',
    icon: List,
    childs: [
      {
        name: 'Toutes',
        count: true,
        icon: List,
        link: (e) =>
          permit(e, { key: 'demandeFilters' })
            ? '/demandes/list'
            : `/demandes/list/all_${e._id}`,
      },
      {
        name: 'Rappels',
        icon: AddAlert,
        count: true,
        link: (e) =>
          `/demandes/list/reminders?query="{"login.reminder":"${e._id}"}"`,
      },
      {
        name: 'À traitées plus tard',
        icon: EventBusy,
        count: true,
        link: (e) =>
          `/demandes/list/checkLater?query="{"login.modification":"${e._id}"}"`,
      },
    ],
  },
];
