import React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { getLogin } from '../../../utils/function';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import Grid from '@mui/material/Grid';

const DisplayAnswers = ({ answers, classes }) =>
  answers.map((e, i) => (
    <React.Fragment key={i}>
      <Grid item xs={12}>
        <Divider />
        <Typography variant='subtitle2'>Réponses envoyées: </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography
          children={e.body.split('\n').map((c) => [c, <br key={0} />])}
          wrap variant="body1" className={classes.lineHeight}
          color="primary"
        />
      </Grid>
      <Grid item xs={4} className={`${classes.email} ${classes.answers}`}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              children={`Répondu le ${dayjs(e.date).format('LL à HH[h]mm')}`}
              variant="caption" color="primary"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              children={`Répondu par ${getLogin(e.userId)}`}
              variant="caption" color="primary"
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  ));

export default DisplayAnswers;
