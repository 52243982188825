import api from '../api';

import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import notifSystem from '../notifSystem';
import collections from '../constants/collections';

export const getAllCalls = ({
  user,
  userId,
  users,
  endAt,
  startAt,
}) =>
  api.records
    .custom('getCallsToday')
    .get({
      startAt: startAt || dayjs().startOf('day').toDate(),
      endAt: endAt || dayjs().endOf('day').toDate(),
      ...(userId ? { userId } : {}),
      ...(user
        ? {
          lignes: user.multiLignes,
          ...(user.ivrNumber ? { ivrNumbers: [user.ivrNumber] } : {})
        }
        : {
          lignes: users
            .toJS()
            .reduce((acc, curr) => acc.concat(curr.multiLignes || []), []),
          ivrNumbers: users
            .toJS()
            .filter(e => e.ivrNumber)
            .map(e => e.ivrNumber)
        }),
    })
    .then((res) => res.body().data())
    .catch(() =>
      notifSystem.error('Erreur system', 'lors de la récupération des appels')
    );

export const updateTransformCall = (
  elem,
  redirect,
  type = collections.interventions
) => {
  if (
    [elem.client.tel1, elem.client.tel2, elem.client.tel3]
      .filter(Boolean)
      .some((e) => e === redirect.number)
  ) {
    api
      .all('transformCall')
      .custom(`update/${redirect.id}`)
      .patch({
        billing: redirect.billing,
        group: redirect.group,
        id: redirect.id,
        update: {
          solved: {
            action: 'intervention',
            solvedCall: 'Intervention effectuée',
            [type === collections.devis ? 'devisId' : 'interId']: elem.id,
            color: 1,
          },
        },
      });
  }
};
