import React from 'react';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
  getKeysConclusionsByType,
  getObjConclusionsByType,
} from '@lba-dev/package.local-globals/rConclusions';

import { getAlias } from '../../../utils/function';
import InterMenuInfo from '../Intervention/InterMenuInfo';

const RecouvrementInfo = ({ recouvrement, classes, elem }) => {
  if (!recouvrement) {
    return null;
  }
  const {
    priseEnCharge,
    montantEncaisse,
    historique = [],
    type,
  } = recouvrement;
  const formatData = [
    {
      date: elem.date.ajoutRecouvrement,
      user: getAlias(elem.login.ajoutRecouvrement),
      type: 'Création'
    },
    {
      date: elem.date.recouvrement,
      user: getAlias(elem.login.recouvrement),
      type:
        priseEnCharge &&
        `Montant prise en charge : ${
          priseEnCharge.montant || 'Le montant n\'est pas defini'
        }`,
    },
    {
      date: elem.date.priseDeContactRecouvrement,
      user: getAlias(elem.login.priseDeContactRecouvrement),
      type:
        montantEncaisse &&
        `Montant encaissé : ${
          montantEncaisse || 'Le montant encaissé n\'est pas defini'
        }`,
    },
    {
      date: elem.date.relanceDate,
      user: getAlias(elem.login.priseDeContactRecouvrement),
      type: 'Dossier relancé',
    },
    ...historique.map(({ userId, date, conclusions = [], comments }) => ({
      user: getAlias(userId),
      date: date,
      type: comments,
      message: conclusions.length
        ? `Conclustions : \n ${conclusions.map((c, i) => {
          const [key = [], obj = null] = [
            getKeysConclusionsByType(type),
            getObjConclusionsByType(type),
          ];
          const newKey = (key || []).find((o) => obj && obj[o]
            .some((t) => t._id === c));
          return `${i + 1} : ${obj && obj[newKey]
            .find((t) => t._id === c).name}`;
        })}`
        : '',
    })),
  ];
  return (
    <Grid item xs={12} md={4}>
      <Typography variant="h6" className={classes.title}>
        Commentaires juridique
      </Typography>
      <div className={classes.timeLineLimit}>
        {formatData.sort((a, b) =>
          dayjs(a.date) - dayjs(b.date)).map(
          (data, i) => data.type && data.date ?
            <InterMenuInfo
              {...data}
              key={i}
              date={dayjs(data.date).format('LLL')}
            /> : ''
        )}
      </div>
    </Grid>
  );
};

export default RecouvrementInfo;
