import React from 'react';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Chip } from '@mui/material';

import { dayjs } from '@lba-dev/package.local-globals/dayjs';

const DisplayName = ({
  sender, classes, message,
  redirect, nameChat, type,
  isAuto, dataObject
}) => {
  const date = new Date(message.get('date'));
  const transmitter = message.get('transmitter');
  let formatEnvoi = (dayjs(date).isSame(dayjs(), 'year') && `${
    dayjs(date).isBetween(dayjs().add(-6, 'day'), dayjs()) ? 'ddd' : 'D MMM'
  }[, ]HH:mm`) || 'D MMM YYYY[, ]HH:mm';
  const displayDate = dayjs(date).isSame(dayjs(), 'day') ?
    (dayjs(date).isSame(dayjs(), 'hour') &&
    dayjs(date).isSame(dayjs(), 'minute') &&
    dayjs(date).fromNow()) ||
    dayjs(date).format('HH:mm')
    : dayjs(date).format(formatEnvoi);
  const isClient = (message.has('intervention') && 'intervention') ||
    (message.has('devis') && 'devis');
  const clientData = isClient &&
    message.get(isClient === 'intervention' ? 'interObject' : 'devisObject');
  const telClientArtisan = message.get(sender ? 'to' : 'from')
    .replace('+33', '0');
  const telObj = ((dataObject.tels || []).find(e =>
    e.tel === telClientArtisan
  ) || { tel: telClientArtisan, shortTitle: 'Tel' });
  const telTitle = telObj.shortTitle || '';
  return <Tooltip
    title={`Ouvrir ${type === 'artisan' ? 'le recap' : 'la fiche'}`}
    disableFocusListener={sender}
    disableHoverListener={sender}
    disableTouchListener={sender}
  >
    <Typography
      className={classes.senderName}
      fontWeight='bold'
      noWrap
      style={{ cursor: !sender ? 'pointer' : '',
        display: 'flex', alignItems: 'center' }}
      gutterBottom>
      <span onClick={() => type && (!sender ?
        redirect(message.get(type), type) :
        type === 'artisan' && isClient && clientData &&
        redirect(message.get(isClient), isClient))
      }>{nameChat}
        {message.get('messageType') ? <Chip
          size='small'
          variant='outlined'
          sx={{
            fontSize: 10, ml: 0.5,
            height: 18,
            cursor: clientData ? 'pointer' : ''
          }}
          label={
            `${message.get('messageType')}${transmitter !== 'AUTO' &&
            isAuto ? ' (AUTO)' : ''}`
          }
        /> : null}
      </span>
      {(telObj.tel ? <Tooltip title={telObj.tel}>
        <Chip
          size='small'
          clickable={false}
          variant='outlined'
          color='primary'
          sx={{
            cursor: 'default',
            fontSize: 10, ml: 0.5, height: 18,
            lineHeight: 1,
            ...(telTitle ? {
              '&>.MuiChip-label': {
                pl: 0,
              }
            } : {})
          }}
          label={[
            telTitle ? <Chip
              key='tel'
              clickable={false}
              size='small'
              color='primary'
              label={telTitle}
              sx={{
                lineHeight: 1,
                cursor: 'default',
                margin: 0,
                fontSize: 10, mr: 0.5, height: 18
              }}
            /> : null,
            (telObj.tel || '').slice(0, 4)
          ]}
        />
      </Tooltip> : null)}
      <Typography
        variant="caption"
        sx={{ ml: 0.5 }}
        children={displayDate}
      />
    </Typography>
  </Tooltip>;
};

export default DisplayName;
