import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import Switch from '@mui/material/Switch';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AdvanceContentPercent from './AdvanceContentPercent';
import AdvanceContentAmount from './AdvanceContentAmount';

import TransactionSended from './TransactionSended';
import {
  permit
} from '@lba-dev/package.local-globals/restrictions';

import {
  L_ADVD,
  data,
} from '@lba-dev/package.local-globals/paymentCBActionTypes';
import {
  paymentCBActionTypes
} from '@lba-dev/package.local-globals/getDataById';
import PaymentContent from './PaymentContent';
import {
  divideCurrencyEvenly
} from '@lba-dev/package.local-globals/comptaCalcules';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import {
  defaultMailText
} from '@lba-dev/package.local-globals/AdvPaymentConstants';
import Money from '../../../../utils/Money';
import { SMS } from '@lba-dev/package.local-globals/smsMode';
import MultiOney from './MultiOney';
import MultiAlma from './MultiAlma';

const styles = {
  buttonDiv: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  container: {
    width: '100%'
  },
  box: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  switchRight: {
    textAlign: 'right'
  },
  dialogTitle: {
    padding: '0 0 16px',
    width: '100%'
  }
};
const dataPaymentActions = data
  .filter((e) => e.title);

const getButtons = (handleType, props) =>
  dataPaymentActions.map((elem, id) => [
    <Grid key={id} item xs={elem.width || 6} lg={elem.width || 6}>
      <Button
        fullWidth
        key={id}
        children={elem.name}
        startIcon={elem.Icon ? <elem.Icon /> : null}
        variant="contained"
        size="large"
        color={elem.color}
        disabled={elem.display(props)}
        onClick={() => handleType(elem._id, elem.typeId)}
      />
    </Grid>,
  ]);

const component = (dialogComponent, props) =>
  [
    <AdvanceContentPercent key={0} {...props} />,
    <AdvanceContentAmount key={1} {...props} />,
    <PaymentContent key={2} {...props} />,
    <MultiOney key={3} {...props} />,
    <MultiAlma key={4} {...props} />,
  ][dialogComponent - 1];

const initState = ({ advancePayment, user }) => ({
  dialogType: null,
  dialogComponent: null,
  noSecure: false,
  date: null,
  mode: SMS,
  activeStep: 0,
  paymentDate: new Date(),
  payments: [],
  loading: false,
  advancePayment: advancePayment || new Map(),
  userId: user._id
});

const mapStateToProps = ({ users, userId }) => ({
  users,
  user: users.find(u => u._id === userId)
});

class Content extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...initState(props),
      pass: !!props.transaction.login,
    };
  }

  handleType = (dialogType, dialogComponent) => {
    this.setState({ dialogType, dialogComponent }, () =>
      this.props.setPaymentState(this.state));
  };

  setPourcent = (path, v) => {
    const otherPaymentType = path[1] === 'pourcent'
      ? 'montant'
      : 'pourcent';
    this.setState(({ advancePayment }) => ({
      advancePayment: advancePayment.merge({
        [path[1]]: v,
        [otherPaymentType]: 0
      })
    }), () => {
      this.props.setPourcent(path, v);
      const { user } = this.props;
      const mail = defaultMailText({
        id: this.props.id,
        products: (this.props.products || new Map()).toJS(),
        helps: this.props.helps,
        advPayment: (this.state.advancePayment || new Map())
          .toJS(),
        user
      });
      this.setMail(mail);
      this.props.setPaymentState(this.state);
    });
  }

  setMail = (mail) => {
    this.setState(({ advancePayment }) => ({
      advancePayment: advancePayment.setIn(['mail'], mail)
    }), () => this.props.setMail(mail));
  }

  componentDidMount() {
    const { initStateAgain, setPaymentState, setLoadingPayment, setPass } =
      this.props;
    initStateAgain(() => {
      this.setState(initState(this.props));
      setPaymentState(initState(this.props));
    });
    setPaymentState(this.state);
    setLoadingPayment((loading) => {
      this.setState({ loading });
    });
    setPass((pass) => this.setState({
      pass
    }));
  }

  handleChange = (name) => (v) => {
    const { finalPrice, tva, advancePaymentMontant = 0 } = this.props;
    const update = { [name]: v };
    if (name === 'mode') {
      update.payments = divideCurrencyEvenly(
        Math.round(finalPrice * ((tva / 100) + 1)) - advancePaymentMontant,
        v
      ).map((e, i) => ({
        ttc: Money.toString(e),
        date:
          i === 0
            ? dayjs().toDate()
            : dayjs().add(i, 'month').startOf('month').toDate(),
      }));
    }
    this.setState(update, () =>
      this.props.setPaymentState(this.state));
  };

  updatePayments = (index, payment) => {
    const newPayements = this.state.payments
      .map((e, i) => i === index ? payment : e);
    this.setState({ payments: newPayements }, () =>
      this.props.setPaymentState(this.state));
  }

  render() {
    const {
      classes,
      users,
      user,
      transaction,
      id,
      ...props
    } = this.props;
    const {
      dialogType,
      dialogComponent,
      pass = !!transaction.login,
      noSecure,
      loading,
      advancePayment,
      ...othersStates
    } = this.state;
    return [
      <DialogTitle key={0} className={classes.dialogTitle}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            {(dialogComponent &&
                paymentCBActionTypes[dialogType] &&
                (paymentCBActionTypes[dialogType].title ||
                  paymentCBActionTypes[dialogType].name)) ||
                'Choisissez le mode de paiement à envoyer'}
          </Grid>
          <Grid item xs={4} className={classes.switchRight}>
            <FormControlLabel
              control={
                <Switch
                  checked={!noSecure}
                  onChange={() => this.handleChange('noSecure')(!noSecure)}
                  name="noSecure"
                  color="primary"
                  disabled={!permit(user, { key: 'disable3DSecure' })}
                />
              }
              label={`${!noSecure
                ? 'activé'
                : 'non activé'}
                3D secure (reservé au chef d'équipe et à l'équipe partenaire)`
              }
            />
          </Grid>
        </Grid>
      </DialogTitle>,
      loading ? <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <CircularProgress size={50} />
        </Grid>
      </Grid> : null,
      !loading && pass && transaction.type ? (
        <TransactionSended
          transaction={transaction} users={users} id={id} />
      ) : (
        !loading && <div
          className={`${
            dialogType !== L_ADVD ? classes.buttonDiv : ''
          } ${classes.container}`}
        >
          {dialogComponent ? (
            component(dialogComponent, {
              handleChange: this.handleChange,
              updatePayments: this.updatePayments,
              transaction,
              ...othersStates,
              ...props,
              id,
              advancePayment: advancePayment,
              setPourcent: this.setPourcent,
              setMail: this.setMail
            })
          ) : (
            <Grid container spacing={1}>
              {getButtons(this.handleType, this.props)}
            </Grid>
          )}
        </div>
      )
    ];
  }
}


export default connect(mapStateToProps)(withStyles(Content, styles))
