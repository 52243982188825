import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import { cacheDisabled, getAlias, getAliasFromPhone,
  loginToId } from '../../../../utils/function';
import { styles } from '../../utils';
import api from '../../../../api';
import notifSystem from '../../../../notifSystem';
import AudioPlayer from '../../../../components/Calls/AudioPlayer';
import { SMSType } from '@lba-dev/package.local-globals/smsType';
import { Link } from '@mui/material';
import { hangupReasonsNoAswers } from '@lba-dev/package.local-globals/calls';
import { SHOW_ENR } from '@lba-dev/package.local-globals/configTypes';

const directions = {
  'outbound': 'Sortant',
  'inbound': 'Entrant'
};

class SearchNumber extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 20,
      allData: []
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  async componentDidMount() {
    try {
      const [calls, sms, callsLogs] = await Promise.all([
        this.getCalls(),
        this.getSMS(),
        this.getCallsLogs()
      ]);

      const formattedCalls = calls.map(call => ({
        id: call._id,
        agent: call.record.agent,
        date: new Date(call.record.callStart),
        type: 'call',
        userId: call.record.userId,
        direction: directions[call.record.direction] || 'Entrant',
        content: call.record.fileUrl,
        service: call.service,
        group: call.group,
        duration: call.record.duration
      }));

      const formattedSMS = sms.body().map(sms => {
        const data = sms.data();
        return {
          id: data._id,
          agent: data.sender ?
            data.transmitter : data.recipient,
          date: new Date(data.date),
          type: data.type,
          service: data.path ? 'MMS' : 'SMS',
          direction: data.sender ? 'Sortant' : 'Entrant',
          content: data.path || data.text,
        };
      });

      const formattedCallsLogs = callsLogs.body().map(call => {
        const {
          hangupCause,
          newCustomer,
          _id,
          agent,
          date,
          billing
        } = call.data();
        return {
          id: _id,
          agent: agent,
          date: new Date(date),
          type: 'sms',
          service: billing.description,
          direction: 'Entrant',
          content:
          `${hangupCause} ( ${newCustomer ? 'nouveau' : 'ancien'} client )`
        }
      });

      const allData = [
        ...formattedCalls,
        ...formattedSMS,
        ...formattedCallsLogs
      ].sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      this.setState({ allData });
    } catch (error) {
      notifSystem.error(
        'Erreur',
        'Impossible de récupérer les données concernant ce numéro'
      );
    }
  }


  async getCalls() {
    try {
      const result = await api.records.custom('search').get({
        number: this.props.number
      });
      const calls = result.body().data().calls;
      return calls;
    } catch {
      throw new Error('Impossible de récupérer les appels pour ce numéro');
    }
  }

  async getCallsLogs() {
    try {
      const numberWithoutZero = this.props.number.substring(1);
      const calls = await api.callLog.getAll({
        query: JSON.stringify({
          calling: `0033${numberWithoutZero}`,
          hangupCause: {
            $in: hangupReasonsNoAswers
          }
        }),
      });
      return calls;
    } catch {
      throw new Error('Impossible de récupérer les SMS pour ce numéro');
    }
  }

  async getSMS() {
    try {
      const numberWithoutZero = this.props.number.substring(1);
      const sms = await api.sms.getAll({
        query: JSON.stringify({
          $and: [
            { $or: [
              { from: { $in: [`+33${numberWithoutZero}`, this.props.number] } },
              { to: { $in: [`+33${numberWithoutZero}`, this.props.number] } },
            ] },
            { $or: [
              { type: SMSType.SMSIN },
              { type: SMSType.SMSOUT },
            ] }
          ]
        }),
      });
      return sms;
    } catch {
      throw new Error('Impossible de récupérer les SMS pour ce numéro');
    }
  }

  handleChangePage(event, page) {
    this.setState({ page });
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value });
  }

  getAgentDisplayName = (conversation) => {
    if (conversation.service === 'SMS') {
      const agent = getAlias(loginToId(conversation.agent));
      return agent.length ? agent : conversation.agent;
    } else if (!conversation.userId) {
      return getAliasFromPhone(conversation.agent.replace(/^0033/, '0'), false);
    }
    return getAlias(conversation.userId);
  };

  reduceTextMessageOrDisplayLink = (conversation) => {
    if (conversation.service === 'MMS')
    {
      return (
        <Link
          target="_blank"
          href={conversation.content}
        >
          {conversation.content}
        </Link>
      );
    }
    if (conversation.content && conversation.content.length > 100) {
      conversation.content = `${conversation.content.substring(0, 100)}...`;
    }
    return conversation.content;
  }

  render() {
    const { closeDisagreeListener, open, classes } = this.props;
    const { allData, rowsPerPage, page } = this.state;
    const rows = allData.slice(
      page * rowsPerPage,
      (page * rowsPerPage) + rowsPerPage
    );
    const hideAudio = !cacheDisabled(SHOW_ENR);
    return (
      <Dialog onEscapeKeyDown={closeDisagreeListener}
        maxWidth={false}
        open={open}>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                {
                  ['Date', 'Agent', 'Ligne', 'Service', 'Direction',
                    ...(hideAudio ? ['Audio/Message'] : [])].map(
                    (title) => (
                      <TableCell key={title}>{title}</TableCell>
                    )
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length ? (
                rows.map((conversation, i) => (
                  <TableRow key={i}>
                    <TableCell key="callStart">
                      {dayjs(conversation.date).format('L[ à ]HH[h]mm')}
                    </TableCell>
                    <TableCell key="agent">
                      {this.getAgentDisplayName(conversation)}{'\n'}
                      {conversation.group}
                    </TableCell>
                    <TableCell
                      key="group"
                      align="center"
                    >{(conversation.group || '').replace(/^(0033|\+33)/, '0')}</TableCell>
                    <TableCell
                      key="group"
                      align="center"
                    >{conversation.service}</TableCell>
                    <TableCell key="group" align="center">
                      {conversation.direction || 'Oui'}
                    </TableCell>
                    <TableCell
                      key={conversation.type === 'call' ? 'urlAudio' : 'group'}
                      className={conversation.type === 'call'
                        ? classes.audioDialog : undefined}
                      style={conversation.type === 'sms' ?
                        { lineHeight: 1.8 } : undefined}
                    >
                      {conversation.type === 'call' ? (
                        <AudioPlayer
                          noPadding={true}
                          url={conversation.content}
                          duration={conversation.duration}
                        />
                      ) : (
                        this.reduceTextMessageOrDisplayLink(conversation)
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center" key="not found">
                    Aucun enregistrements correspondant
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  colSpan={5}
                  count={allData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDisagreeListener} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(SearchNumber, styles);
