/* eslint-disable max-lines */
import React from 'react';
import { Link } from 'react-router-dom';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';

import { deepOrange, red, green, orange, blue } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import {
  Delete,
  AddAlert,
  Assignment,
  Mail,
  Person,
  Send,
  CheckCircle,
  Close,
  Comment,
} from '@mui/icons-material';

import signalementsFilters
  from '@lba-dev/package.local-globals/Filters/SignalementsFIlters';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import { checkDroit } from
  '@lba-dev/package.local-globals/checkSignalementsPermission.js'
import { data as sData, S_RES } from '@lba-dev/package.local-globals/sStatus';
import {
  data as aSubStatusData
} from '@lba-dev/package.local-globals/aSubStatus';
import { data as aStatusData } from '@lba-dev/package.local-globals/aStatus';
import {
  data as csData,
  CS_NO,
  CS_MAIL_RES,
  CS_RES,
  CS_MAIL_STD,
  CS_UNC,
  CS_REL,
} from '@lba-dev/package.local-globals/csStatus';
import { S_PART } from '@lba-dev/package.local-globals/services';

import CustomTextField from '../CustomInputs/CustomTextField';
import CommentDialog from '../Dialogs/Signalements/CommentDialog';
import LinkedDialog from '../Dialogs/Signalements/LinkedDialog';
import InformDialog from '../Dialogs/Signalements/InformDialog';
import {
  commentSignalement,
  setManagerSignalement,
  addSignalementFrom,
  inform,
  addReminder,
  removeFromMySignalements,
} from '../../actions/signalements';
import { loginToId, getDataInObject } from '../../utils/function';
import notifSystem from '../../notifSystem';
import { setDialog } from '../../actions/dialog';
import store from '../../store';

export const styles = ({ elem, user }) => ({
  listRow: {
    backgroundColor:
      elem.quarantaine && user.service === S_PART ? '#ef9191' : 'white',
  },
});

export const generateTabs = () => {
  let i = 1;

  return Object.entries(signalementsFilters()).reduce((a, [k, v]) => {
    if (v.name) {
      a.push({
        id: i++,
        color: v.color,
        name: v.name,
        group: v.group,
        match: v.match,
        date: v.key,
        counterName: `signalements.${k}`,
      });
    }
    return a;
  }, []);
};

export const s_COLUMNS = (user) => [
  'Date',
  'Quarantaine',
  'Signalement',
  'Emis par',
  'Artisan',
  'Status',
  'Sous-statut SST',
  'Code postal',
  'OS',
  'Intervention ajoutée',
  'Intervention vérifiée',
  ...(user.service === S_PART ? ['Artisan ajoutée'] : []),
  'Statut',
];

export const s_KEYS = (user) => [
  'date',
  'quarantaine',
  'name',
  'emittedBy',
  'artisan',
  'artisanStatus',
  'artisanSubStatus',
  'addressZipcode',
  'osId',
  'interventionAddedBy',
  'interventionVerifiedBy',
  ...(user.service === S_PART ? ['artisanAddedBy'] : []),
  'status',
];

export const S_FILTERKEYS = [
  'date.creation',
  'quarantaine',
  'signal.name',
  'login.ajout',
  'artisan.name',
  'artisan.subStatus',
  'artisan.address.zipcode',
  'artisan.status',
  'intervention.id',
  'intervention.login.creation',
  'intervention.login.verification',
  'artisan.login.ajout',
  'status',
];

export const baseFilter = {
  date: {
    from: null,
    to: null,
  },
  quarantaine: null,
  artisan: null,
  artisanStatus: [],
  artisanSubStatus: [],
  name: null,
  emittedBy: null,
  osId: null,
  interventionAddedBy: null,
  interventionVerifiedBy: null,
  artisanAddedBy: null,
  status: [],
};

export const baseFields = {
  date: 'date.creation',
};

const KEYS_CONVERTER = {
  'date.creation': 'date',
  'date.solved': 'date',
  'date.managed': 'date',
  'inform.date': 'date',
  'signal.name': 'name',
  'login.creation': 'emittedBy',
  'artisan.name': 'artisan',
  'artisan.status': 'artisanStatus',
  'artisan.subStatus': 'artisanSubStatus',
  'artisan.address.zipcode': 'addressZipcode',
  'intervention.id': 'osId',
  'intervention.login.creation': 'interventionAddedBy',
  'intervention.login.verification': 'interventionVerifiedBy',
  'artisan.login.ajout': 'artisanAddedBy',
};

const isEmpty = (v) =>
  v === undefined ||
  v === null ||
  (v instanceof Array && (!v.length || !v.some((o) => o || o === false))) ||
  (typeof v === 'object' &&
    (!Object.keys(v).length ||
      !Object.values(v).some((o) => o || o === false)));

export const reformatFilter = (filter, date = 'date.creation') => {
  const formattedFilter = {
    [date]: {
      $gte: filter.date.from,
      $lte: filter.date.to,
    },
    quarantaine: filter.quarantaine || null,
    'signal.name': {
      $regex: filter.name,
      $options: 'i',
    },
    'artisan.name': {
      $regex: filter.artisan,
      $options: 'i',
    },
    'artisan.status': {
      $in: filter.artisanStatus,
    },
    'artisan.subStatus': {
      $in: filter.artisanSubStatus,
    },
    'artisan.address.zipcode': filter.addressZipcode ? {
      $regex: `^${filter.addressZipcode}`,
      $options: 'i',
    } : null,
    'login.creation': [loginToId(filter.emittedBy)],
    'intervention.id': {
      $eq: filter.osId,
    },
    'intervention.login.creation': [loginToId(filter.interventionAddedBy)],
    'intervention.login.verification': [
      loginToId(filter.interventionVerifiedBy),
    ],
    'artisan.login.ajout': [loginToId(filter.artisanAddedBy)],
    status: {
      $in: filter.status,
    },
    _id: (filter._id || []).slice(0, 200),
  };

  Object.keys(formattedFilter).forEach((key) => {
    if (
      isEmpty(filter[KEYS_CONVERTER[key] || key]) ||
      isEmpty(formattedFilter[key])
    ) {
      delete formattedFilter[key];
    }
  });
  return formattedFilter;
};

const generateUser = (user) =>
  user
    ? {
      _id: user._id,
      name: user.login || '',
    }
    : {};

export const formatReport = (report, users, userId, fields = baseFields) => {
  let commentIndex = 1;
  const inform = report.inform.find((o) => o.user === userId);
  const format = {
    _id: report._id,
    description: report.description || '',
    inform: inform
      ? {
        date: dayjs(inform.date).format('L'),
        notification: inform.notification,
        mail: inform.mail,
      }
      : null,
    comments: report.comments.map((c) => {
      let message = '';
      const user = users.find((u) => u._id === c.user);
      const date = dayjs(c.creation).format('L');
      const commentReminder = (report.reminders || []).find(
        (r) => r.comment === c.comment
      );
      const reminderDate = commentReminder
        ? dayjs(commentReminder.reminderDate).format('DD/MM/YY [à] HH[h]mm')
        : null;

      if (c.status !== CS_NO) {
        const status = csData.find((d) => d._id === c.status);

        message = status ? status.name : '';
      }
      return {
        title: message || commentIndex++,
        description: `${date} - ${(c.artisanName && `${c.artisanName}(Artisan)`)
        || (user ? user.login : '')} ${
          c.signal ? `Signalement émis: ${c.signal}` : ''
        }`,
        date,
        user: user ? user.login : '',
        artisanName: c.artisanName,
        comment: c.comment,
        reminderDate,
      };
    }),
    quarantaine: report.quarantaine || false,
    recipients: (report.recipients || []).map((id) =>
      generateUser(users.find((u) => u._id === id))
    ),
    date: getDataInObject({ ...report, inform }, fields.date.split('.')),
    creationDate: report.date.creation,
    name: report.signal.name || '',
    emittedBy: generateUser(users.find((u) => u._id === report.login.creation)),
    status: report.status || 0,
    counter: report.counter ? `${report.counter} émis` : ''
  };

  if (report.date.modification) {
    format.modifiedDate = report.date.modification;
  }
  if (report.login.managed) {
    format.managedBy = generateUser(
      users.find((u) => u._id === report.login.managed)
    );
    format.managedDate = report.date.managed;
  }
  if (report.status === S_RES) {
    format.solvedBy = generateUser(
      users.find((u) => u._id === report.login.solved)
    );
    format.solvedDate = report.date.solved;
  }
  if (report.intervention) {
    format.osId = report.intervention.id;
    format.interventionAddedBy = generateUser(
      users.find((u) => u._id === report.intervention.login.creation)
    );
    format.interventionVerifiedBy = generateUser(
      users.find((u) => u._id === report.intervention.login.verification)
    );
  }
  if (report.artisan) {
    format.artisan = report.artisan;
    format.artisanStatus = report.artisan.status;
    format.artisanSubStatus = report.artisan.subStatus;
    if (report.artisan.address) {
      format.addressZipcode = report.artisan.address.zipcode;
    }
    if (report.artisan.login) {
      format.artisanAddedBy = generateUser(
        users.find((u) => u._id === report.artisan.login.ajout)
      );
    }
  }
  return format;
};

export const formatReports = (reports, ...props) =>
  reports.map((report) => formatReport(report, ...props));

const displayUserLogin = (obj) => (!!obj && obj.name) || 'Pas d\'utilisateur';

const chipFromId = (data) => (id) => {
  const elem = data.find((e) => e._id === id);

  if (elem) {
    return (
      <Chip
        label={elem.name}
        style={{
          color: 'white',
          display: 'inline-flex',
          backgroundColor: elem.color['500'],
        }}
      />
    );
  }
};

export const displayers = (user) => ({
  date: (d) => (d ? dayjs(d).format('L') : 'Pas de date'),
  quarantaine: (q) => (q ? 'Oui' : 'Non'),
  artisan: (obj) =>
    obj ? (
      <Link to={`/artisan/${obj.id}/recap`}>{obj.name}</Link>
    ) : (
      'Pas d\'artisan'
    ),
  artisanStatus: chipFromId(aStatusData),
  artisanSubStatus: chipFromId(aSubStatusData),
  osId: (id) =>
    id ? (
      <Link to={`/intervention/${id}`}>{id}</Link>
    ) : (
      'Pas d\'intervention liée'
    ),
  status: chipFromId(sData),
  emittedBy: displayUserLogin,
  interventionAddedBy: displayUserLogin,
  interventionVerifiedBy: displayUserLogin,
  ...(user.service === S_PART ? { artisanAddedBy: displayUserLogin } : {}),
});

const indexToDateKeys = [
  {
    name: 'Création',
    value: 'date.creation',
  },
  {
    name: 'Prise en charge',
    value: 'date.managed',
  },
  {
    name: 'Résolution',
    value: 'date.solved',
  },
  {
    name: 'Suivi',
    value: 'inform.date',
  },
];

export const headersDisplayers = {
  date: (value, onChange) => (
    <FormControl style={{ width: 100 }}>
      <InputLabel>Date</InputLabel>
      <Select
        value={value}
        label="Date"
        onChange={(e) => onChange(e.target.value)}
      >
        {indexToDateKeys.map((e) => (
          <MenuItem key={e.value} value={e.value} children={e.name} />
        ))}
      </Select>
    </FormControl>
  ),
};

const generateTextField = (type) => (value, onChange, onRequest) =>
  (
    <CustomTextField
      type={type}
      style={{ width: 100 }}
      value={value || ''}
      onBlur={onRequest}
      multiline={false}
      onKeyDown={(e) => e.key === 'Enter' && onRequest()}
      setData={(p, v) => onChange(v)}
    />
  );

const generateSelectField = (data, name) => (array, onChange, onRequest) =>
  (
    <Select
      multiple
      value={[name]}
      renderValue={(selected) => selected}
      onChange={(e) => {
        const v = +e.target.value[1];

        onChange(
          array.includes(v) ? array.filter((i) => i !== v) : [...array, v],
          onRequest
        );
      }}
    >
      {Array.isArray(array) &&
        data.map((d) => (
          <MenuItem key={d._id} value={d._id.toString()}>
            <Checkbox checked={array.includes(d._id)} />
            {d.name}
          </MenuItem>
        ))}
    </Select>
  );

export const searchDisplayers = {
  date: (value, onChange, onRequest) => (
    <FormControl style={{ width: 100 }}>
      <InputLabel>Mois</InputLabel>
      <Select
        value={value && value.from ? value.from.getMonth() : ''}
        label="Mois"
        onChange={(e) => {
          const date = dayjs().set('month', e.target.value);

          onChange(
            !!e.target.value || e.target.value === 0
              ? {
                from: date.startOf('month').toDate(),
                to: date.endOf('month').toDate(),
              }
              : {
                from: null,
                to: null,
              },
            onRequest
          );
        }}
      >
        <MenuItem>Tous</MenuItem>
        {dayjs.months().map((e, id) => (
          <MenuItem key={id} value={id}>
            {e}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ),
  quarantaine: (value, onChange, onRequest) => (
    <Switch
      checked={value}
      onChange={(e) => onChange(e.target.checked, onRequest)}
    />
  ),
  name: generateTextField(),
  emittedBy: generateTextField(),
  artisan: generateTextField(),
  artisanStatus: generateSelectField(aStatusData, 'Status'),
  artisanSubStatus: generateSelectField(
    aSubStatusData.filter((e) => e.assignable),
    'Sous-statut SST'
  ),
  addressZipcode: generateTextField(),
  osId: generateTextField('number'),
  interventionAddedBy: generateTextField(),
  interventionVerifiedBy: generateTextField(),
  artisanAddedBy: generateTextField(),
  status: generateSelectField(sData, 'Statut'),
};


const generateGoogleCalendarDetails = (report) =>
  `Ce signalement est lié aux entités suivantes :\n${
    report.artisan
      ? `- ${window.location.origin}/artisan/${report.artisan.id}/recap`
      : ''
  }${report.artisan && report.osId ? '\n' : ''}${
    report.osId ? `- ${window.location.origin}/intervention/${report.osId}` : ''
  }`;

export const actions = [
  {
    name: 'Récap',
    icon: Person,
    action: (props, elem) =>
      props.navigate(`/artisan/${elem.artisan.id}/recap`),
    customVisible: (elem, { selected }) => !!selected.artisan,
  },
  {
    name: 'Intervention',
    icon: Assignment,
    action: (props, elem) => props.navigate(`/intervention/${elem.osId}`),
    customVisible: (elem, { selected }) => !!selected.osId,
  },
  {
    name: 'Signalement',
    color: red,
    icon: Mail,
    action: ({ setDialog, reloadCurrent, launchRequest }, elem) =>
      setDialog(LinkedDialog, elem, {
        comment: ({ text, description }, signal, resolve, intervention) =>
          addSignalementFrom(
            elem._id,
            text,
            resolve ? CS_MAIL_RES : CS_MAIL_STD,
            {
              signal,
              description,
              intervention,
            }
          ),
        reload: () => reloadCurrent(elem._id),
        reloadAll: launchRequest,
        close: () => setDialog(),
      }),
  },
  {
    name: 'Supprimer de mes signalements',
    color: red,
    icon: Delete,
    customVisible: (elem, { selected, user }) =>
      permit(user, { key: 'selectReportManager' }) &&
      selected.recipients.length &&
      !!selected.recipients.filter((e) => e._id === user._id).length,
    action: (props, elem) => removeFromMySignalements(elem._id, props.user._id),
  },
  {
    name: 'Commenter',
    color: blue,
    icon: Comment,
    action: ({ setDialog, reloadCurrent }, elem) =>
      setDialog(CommentDialog, elem, {
        comment: (text) => commentSignalement(elem._id, text, CS_NO),
        reload: () => reloadCurrent(elem._id),
        close: () => setDialog(),
      }),
  },
  {
    name: 'Résoudre',
    color: green,
    icon: CheckCircle,
    action: ({ setDialog, launchRequest }, elem) =>
      setDialog(
        (props) => <CommentDialog {...props} label="Résoudre le signalement" />,
        elem,
        {
          comment: (text) =>
            commentSignalement(elem._id, text, CS_RES),
          reload: launchRequest,
          close: () => setDialog(),
        }
      ),
    customVisible: (elem, { selected, user }) =>
      selected.status !== S_RES &&
      checkDroit(selected, user)
  },

  {
    name: 'Déclôturer',
    color: deepOrange,
    icon: Close,
    action: ({ setDialog, launchRequest }, elem) =>
      setDialog(
        (props) => (
          <CommentDialog {...props} label="Déclôturer le signalement" />
        ),
        elem,
        {
          comment: (text) => commentSignalement(elem._id, text, CS_UNC),
          reload: launchRequest,
          close: () => setDialog(),
        }
      ),
    customVisible: (elem, { selected }) => selected.status === S_RES,
  },
  {
    name: 'Gérer',
    color: orange,
    icon: Person,
    action: ({ user, reloadCurrent }, elem) =>
      setManagerSignalement(elem._id, user._id)
        .then(() => {
          notifSystem.success(
            'Signalement',
            'Vous avez pris en charge ce signalement'
          );
          reloadCurrent(elem._id);
        })
        .catch(() =>
          notifSystem.error(
            'Erreur',
            'Vous ne pouvez pas prendre en charge ce signalement'
          )
        ),
    customVisible: (elem, { selected, user }) =>
      selected.status !== S_RES &&
      !selected.managedBy &&
      permit(user, { key: 'manageReport' }),
  },
  {
    name: 'M\'informer',
    color: orange,
    icon: AddAlert,
    action: ({ setDialog, reloadCurrent, user }, elem) =>
      setDialog((props) => <InformDialog {...props} user={user} />, elem, {
        inform: (notification, mail) => inform(elem._id, notification, mail),
        reload: () => reloadCurrent(elem._id),
        close: () => setDialog(),
      }),
  },
  {
    name: 'Rappel',
    color: blue,
    icon: Send,
    action: (p, elem) =>
      store.dispatch(
        setDialog({
          name: 'GoogleCalendarDialog',
          open: true,
          dialogProps: {
            title: 'Rappel de signalement',
          },
          contentProps: {
            title: 'Rappel de signalement',
            details: generateGoogleCalendarDetails(elem),
            time: dayjs().format('YYYY-MM-DDTHH:mm'),
          },
          actions: [
            {
              children: 'Valider',
              onClick: ({ message, time }, close) =>
                Promise.all([
                  commentSignalement(elem._id, message, CS_REL),
                  addReminder(elem._id, message, new Date(time).getTime()),
                ])
                  .then(() => {
                    notifSystem.success('Opération réussie', 'Rappel créé');
                    close();
                  })
                  .catch((e) => notifSystem.error('Erreur', e.message)),
            },
          ],
        })
      ),
  },
];
