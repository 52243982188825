import React from 'react';
import { connect } from 'react-redux';
import { data } from '@lba-dev/package.local-globals/statusProsp';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import Button from '@mui/material/Button';
import MinCustomDialog from '../Dialogs/MinCustomDialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {
  P_TYPE_RECALL
} from '@lba-dev/package.local-globals/prospRememberStatus';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import AudioPlayer from '../Calls/AudioPlayer';

const mapStateToProps = ({ users, userId }) => ({
  userId,
  users,
  user: users.find(e => e._id === userId)
});

const DialogHist = ({
  openHist,
  hist,
  updateState,
  users,
  reminders,
  appels,
  user
}) => (
  <MinCustomDialog
    open={openHist}
    fullWidth={false}
    onClose={() => updateState('openHist', false)}
    maxWidth={false}
    title="HISTORIQUE D'APPEL"
    actions={[<Button onClick={() => updateState('openHist', false)} key={1}
      color="primary"> Fermer </Button>]}
    middle
  >
    <div>
      <Table>
        <TableBody>
          {hist && hist.map((el, i) => {
            const user = users.find(u =>
              u._id === el.userId);
            return (
              <TableRow key={i}>
                <TableCell>{user ? user.login : 'Introuvable'}</TableCell>
                <TableCell style={{ maxWidth: 400 }}>
                  {el.comment || ''}</TableCell>
                <TableCell>{data.find(u =>
                  u._id === el.status).name}</TableCell>
                <TableCell>{dayjs(el.date)
                  .format('dddd DD MMMM YYYY[ à ]HH:mm')}</TableCell>
              </TableRow>
            );
          })}
          {reminders && reminders.map(el => (
            <TableRow key={el.get('userId')}>
              <TableCell>
                {users.find(u => u._id === el.get('userId')) ?
                  users.find(u => u._id === el.get('userId')).login : ''}
              </TableCell>
              <TableCell colSpan={2}>
                {`${el.getIn(['type']) === P_TYPE_RECALL ?
                  'rappel prévue le' : 'absence jusqu\'au'} 
                  ${dayjs(el.getIn(['date', 'reminder']))
              .format('dddd DD MMMM YYYY[ à ]HH:mm')}`}
              </TableCell>
              <TableCell>
                {dayjs(el.getIn(['date', 'creation'], ''))
                  .format('dddd DD MMMM YYYY[ à ]HH:mm')}
              </TableCell>
            </TableRow>
          ))}
          {appels && appels.map((el, i) => {
            const appelUser = users.find(u =>
              u._id === el.userId);
            return (
              <TableRow key={i}>
                <TableCell>{
                  appelUser ? appelUser.login : 'Introuvable'
                }</TableCell>
                <TableCell style={{ maxWidth: 400 }}>
                  {`${el.direction && el.direction === 'inbound' ?
                    ' a été appelé par le ' : ' a appelé le '}
              ${el.number}`}</TableCell>
                <TableCell key={'urlAudio'} style={{ minWidth: 325 }}>
                  {(users.find(u => u._id === el.userId) === user
                      || permit(user))
                      && <AudioPlayer noPadding url={el.url}
                        duration={el.duration} />}
                </TableCell>
                <TableCell>{dayjs(el.date)
                  .format('dddd DD MMMM YYYY[ à ]HH:mm')}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  </MinCustomDialog>
);
export default connect(mapStateToProps)(DialogHist);
