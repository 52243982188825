import React, { PureComponent } from 'react';
import { withStyles } from 'tss-react/mui';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {
  Close, Edit, Email, Error, RadioButtonChecked, Reply
} from '@mui/icons-material';
import classNames from 'classnames';
import { genMail, sendMkukuMail } from '../../../actions/caldeo';
import { fromJS } from 'immutable';
import { formatDate } from '@lba-dev/package.local-globals/dayjs';
import { grey } from '@mui/material/colors';
import CustomTextField from '../../CustomInputs/CustomTextField';
import Attachments from '../../Utils/Attachments';

const styles = ({ palette }) => ({
  emailButton: {
    backgroundColor: palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: palette.primary.main,
    }
  },
  errorButton: {
    backgroundColor: palette.secondary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: palette.secondary.main,
    }
  },
  titleIcon: {
    color: '#fff',
  },
  listContainer: {
    maxHeight: 280,
    overflow: 'auto'
  },
  link: {
    '&:hover': {
      background: grey[300]
    }
  },
  loadingDiv: {
    textAlign: 'center'
  },
  listAction: {
    right: 60
  },
  expensIcon: {
    marginRight: -12
  }
});

const getEmail = (merge, setData, setLoading) => {
  setLoading(true);
  genMail(merge.get('id'))
    .then(e => setData(['mprEmail', 'email'], e))
    .finally(() => setLoading(false));
};

class CaldeoMPR extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMailInput: false,
      subject: '',
      message: '',
      dest: '',
      messageId: '',
      attachments: [],
    };
  }

  updateData = (p, v) => {
    this.setState({
      [p]: v
    });
  }

  handleShowInput = (e, subject = '', dest = '', messageId = '') => {
    this.setState((prevState) => ({
      showMailInput: !prevState.showMailInput,
      subject: !prevState.showMailInput ? subject : '',
      dest: !prevState.showMailInput ? dest : '',
      messageId: !prevState.showMailInput ? messageId : '',
      message: '',
      attachments: [],
    }));
  }

  setAttachments = (f, files) => {
    this.setState({
      attachments: files
    });
  }

  sendMail = async () => {
    const {
      message, subject, dest, attachments, messageId
    } = this.state;
    this.updateData('loading', true);
    await sendMkukuMail({
      devisId: this.props.merge.get('id'),
      subject, message, dest,
      attachments, messageId,
    }).then(() => this.updateData('dest', ''))
      .finally(() => this.updateData('loading', false));
  }

  render () {
    const {
      merge, classes, setData, setLoading,
    } = this.props;
    const {
      showMailInput, message, subject, dest,
      attachments, loading
    } = this.state;
    return <>
      <Grid item xs={12}>
        <List component="nav">
          <ListItem
            color='primary'
            disableRipple
            disableTouchRipple
            divider={!!merge.getIn(['mprEmail', 'email'])}
            className={classNames({
              [classes.errorButton]: !merge.getIn(['login', 'envoiCaldeo']),
              [classes.emailButton]: !merge.getIn(['mprEmail', 'email']) &&
              merge.getIn(['login', 'envoiCaldeo'])
            })}
          >
            <ListItemIcon className={
              classNames({
                [classes.titleIcon]: !merge.getIn(['mprEmail', 'email']) ||
                !merge.getIn(['login', 'envoiCaldeo'])
              })
            }>
              {!merge.getIn(['login', 'envoiCaldeo']) ? <Error /> : <Email />}
            </ListItemIcon>
            <ListItemText
              button={!merge.getIn(['mprEmail', 'email'])}
              primary={!merge.getIn(['login', 'envoiCaldeo']) ?
                'Attention, le dossier n\'a pas encore généré' :
                (merge.getIn(['mprEmail', 'email']) ||
            'Générer un nouveau mail')}
              onClick={() => !merge.getIn(['mprEmail', 'email']) &&
              merge.getIn(['login', 'envoiCaldeo']) &&
              getEmail(merge, setData, setLoading)
              }
            />
            {!!merge.getIn(['mprEmail', 'email']) &&
          !loading ? <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="Email"
                  onClick={this.handleShowInput}
                  size="large">
                  <Tooltip disableInteractive
                    title={showMailInput ? 'annuler' : 'Envoyer un email'}>
                    {showMailInput ? <Close /> : <Edit />}
                  </Tooltip>
                </IconButton>
              </ListItemSecondaryAction> : ''}
          </ListItem>
          {!showMailInput && merge.getIn(['login', 'envoiCaldeo']) &&
          merge.getIn(['mprEmail', 'email']) ? <List component="div"
              className={classes.listContainer}
              disablePadding>
              {merge.get('mailList', fromJS([])).size ?
                merge.get('mailList').reverse().map(e =>
                  <ListItem
                    key={0}
                    button
                    className={`${classes.link} ${
                      e.get('isAnswer') ? classes.answer : ''}`}
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={e.get('link')}>
                    <ListItemText
                      primary={e.get('subject') ?
                        `Objet: ${e.get('subject')}${
                          e.has('attachments') ?
                            ` (${e.get('attachments', '')} PJ)`
                            : ''}` :
                        'Nouveau mail'}
                      primaryTypographyProps={{
                        variant: 'subtitle2'
                      }}
                      secondary={`${e.get('date') ? `${
                        e.get('isAnswer', false) ? 'Envoyé' : 'Reçu'} le ${
                        formatDate(e.get('date'), 'DD/MM/YYYY[ à ]HH:mm')}` : ''
                      }${e.get('from') ? ` par ${e.get('from', '')
                      }` : ''}`}
                      secondaryTypographyProps={{
                        variant: 'caption'
                      }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="reply"
                        onClick={(ev) => {
                          ev.preventDefault();
                          return !e.get('isAnswer', false) &&
                        this.handleShowInput(e,
                          `RE: ${e.get('subject')}`,
                          e.get('from', ''), e.get('messageId', ''));
                        }}
                        size="large">
                        <Tooltip disableInteractive
                          title={!e.get('isAnswer', false) ?
                            'Répondre' : 'Message envoyé'} >
                          {!e.get('isAnswer', false) ?
                            <Reply /> : <RadioButtonChecked
                              fontSize="small" color="secondary"
                            />}
                        </Tooltip>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>) :
                <ListItem>
                  <ListItemText
                    align="center"
                    primary="Aucun mail reçu pour le moment" />
                </ListItem>}
            </List> : null}
        </List>
      </Grid>
      {loading ? <Grid xs={12} className={classes.loadingDiv}>
        <CircularProgress />
      </Grid> : null}
      {!loading && showMailInput ? [
        <CustomTextField
          grid
          key={0}
          xs={12}
          texttransform="none"
          label="Destinataire"
          path='dest'
          value={dest}
          setData={(p, v) => this.updateData(p, v)}
        />,
        <CustomTextField
          grid
          key={1}
          xs={12}
          texttransform="none"
          label="Objet"
          value={subject}
          path='subject'
          setData={(p, v) => this.updateData(p, v)}
        />,
        <CustomTextField
          key={2}
          grid
          xs={12}
          texttransform="none"
          label="Message"
          multiline
          rows={6}
          path='message'
          value={message}
          rowsMax={8}
          setData={(p, v) => this.updateData(p, v)}
        />,
        <Grid item xs={12} key={3}>
          <Attachments
            data={attachments}
            onAdd={this.setAttachments}
            onDelete={this.setAttachments}
          />
        </Grid>,
        <Grid item xs={12} key={4}>
          <Button
            fullWidth
            color="primary"
            children="Envoyer le mail"
            variant="contained"
            onClick={this.sendMail}
          />
        </Grid>
      ] : ''}
    </>;
  }
}

export default withStyles(CaldeoMPR, styles);
