import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../../actions/dialog';
import CustomMaterialTable from '../../CustomMaterialTable';
import {
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { TP_SMS } from '@lba-dev/package.local-globals/templatesType'
import { deleteTemplate, updateTemplate, handleAddTemplate } from './functions';

const styles = {
  TextInput: {
    overflow: 'auto',
    maxHeight: 220,
    minHeight: 200,
    height: 200,
  }
};

const setTemplates = setDialogContentProp('templates');
const setSelectedTemplate = setDialogContentProp('selectedTemplate');
const setDisplayForm = setDialogContentProp('displayForm');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      templates = [],
      selectedTemplate = {},
      displayForm = false,
    },
  },
}) => ({
  templates,
  selectedTemplate,
  displayForm,
});


const mapDispatchToProps = {
  setTemplates,
  setSelectedTemplate,
  setDisplayForm
};

const DialogTemplates = ({
  templates,
  setTemplates,
  setSelectedTemplate,
  selectedTemplate
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [displayForm, setDisplayForm] = useState(false);
  const [newTemplate, setNewTemplate] = useState({
    title: '',
    text: '',
    type: TP_SMS,
  });
  const [editMode, setEditMode] = useState(false);
  const handleRowClick = (_, rowData) => {
    setSelectedTemplate(selectedTemplate._id === rowData._id ? {} : rowData);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          spacing={4}
          py={2}
        >
          <Grid item>
            <Typography variant="h5">
              {editMode ? 'Modifier le template' : 'Templates'}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setEditMode(false);
                setSelectedTemplate({})
                setTemplates(templates);
                setDisplayForm(!displayForm)
              }
              }
              variant="contained"
              color={displayForm ? 'secondary' : 'primary'}
            >
              {displayForm ? 'Retour' : 'Créer un template'}
            </Button>
          </Grid>
        </Grid>
        {!displayForm && (
          <Grid>
            <CustomMaterialTable
              title="SMS"
              columns={[
                { title: 'Titre',
                  field: 'title',
                  render: ({ title }) => <Typography>{title}</Typography>
                },
              ]}
              data={templates}
              onRowClick={(e, rowData) => {
                setEditMode(true);
                setSelectedTemplate(rowData);
                setDisplayForm(!displayForm);
              }}
              options={{
                search: false,
                paging: true,
                pageSize: rowsPerPage,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 25, 50, 100],
                selection: true,
                showSelectAllCheckbox: false,
                showTextRowsSelected: false,
                selectionProps: rowData => ({
                  checked: selectedTemplate.id === rowData.id
                }),
                actionsColumnIndex: -1,
              }}
              onChangeRowsPerPage={pageSize => setRowsPerPage(pageSize)}
              onSelectionChange={(_, rowData) => handleRowClick(_, rowData)}
              editable={{
                onRowDelete:
                (oldData) => deleteTemplate(oldData, setTemplates, templates),
              }}
            />
          </Grid>
        )}
        {displayForm && (
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12}>
              <TextField
                label="Titre"
                variant="outlined"
                onChange={e => {
                  if (editMode) {
                    setSelectedTemplate({
                      ...selectedTemplate,
                      title: e.target.value,
                    });
                  } else {
                    setNewTemplate({
                      ...newTemplate,
                      title: e.target.value,
                    });
                  }
                }}
                value={editMode ? selectedTemplate.title : newTemplate.title}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Contenu"
                variant="outlined"
                multiline
                rows={4}
                InputProps={{
                  style: styles.TextInput
                }}
                fullWidth
                onChange={e => {
                  if (editMode) {
                    setSelectedTemplate({
                      ...selectedTemplate,
                      text: e.target.value,
                    });
                  } else {
                    setNewTemplate({
                      ...newTemplate,
                      text: e.target.value,
                      type: TP_SMS
                    });
                  }
                }}
                value={editMode ? selectedTemplate.text : newTemplate.text}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={editMode ?
                  () => {
                    updateTemplate(
                      selectedTemplate,
                      setTemplates,
                      setDisplayForm,
                      templates,
                    );
                  }
                  : () => handleAddTemplate({
                    newTemplate, setTemplates,
                    setNewTemplate, setDisplayForm,
                    templates,
                  })
                }
              >
              Enregistrer
              </Button>
            </Grid>
          </Grid>

        )}
      </Grid>
    </Grid>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogTemplates);
