import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import {
  P_NONE,
  P_ACCEPTED
} from '@lba-dev/package.local-globals/isValidStatus';

const styles = {
  title: {
    textTransform: 'uppercase',
    fontSize: '1rem',
    color: '#fff',
    lineHeight: 1.5,
  },
  statusButton: {
    minHeight: 30,
    padding: '6px 16px',
    background: '#fff',
    borderRadius: 30,
  },
  chip: {
    backgroundColor: '#fff',
    padding: 2,
    marginLeft: 5,
    fontWeight: 500,
    fontSize: 10,
    height: 18,
    textTransform: 'uppercase',
  },
  valid: {
    color: '#4caf50',
  },
  refused: {
    color: '#e20714',
  },
};

const MenuHeader = ({
  classes, statusColor, status,
  elem = {},
  customTitle
}) =>
  <Grid
    container
    xs={12}
    alignItems="center"
    justifyContent="space-between"
    style={{
      backgroundColor: statusColor,
      padding: '3px 20px',
    }}
  >
    <React.Fragment>
      <Grid container item xs={8} sm={6}>
        <Typography variant="h3" className={classes.title}>
          {customTitle || `PROPOSITION |
          ${(elem.date || {}).proposition
    ? dayjs((elem.date || {}).proposition).format('LLL')
    : ''}`}
        </Typography>
        {elem.proposition && elem.proposition.isValid !== P_NONE ? (
          <Chip
            size="small"
            label={elem.proposition.isValid === P_ACCEPTED
              ? 'validé' : 'refusé'}
            className={`${
              classes[elem.proposition.isValid === P_ACCEPTED
                ? 'valid' : 'refused']
            } ${classes.chip}`}
          />
        ) : (
          ''
        )}
      </Grid>
      {elem.proposition &&
      <Grid item container xs={4} sm={6} justifyContent="flex-end">
        <Button
          variant={'outlined'}
          className={classes.statusButton}
          style={{
            color: statusColor,
            borderColor: `${statusColor}80`,
          }}
        >
          {status.name}
        </Button>
      </Grid>}
    </React.Fragment>
  </Grid>;

export default withStyles(MenuHeader, styles);
