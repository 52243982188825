import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';

import { withStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import MenuItem from '@mui/material/MenuItem';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import { Add, Delete } from '@mui/icons-material';
import { red, blue } from '@mui/material/colors';

import {
  data as motifData,
  destinataires
} from '@lba-dev/package.local-globals/mDecaissement';

import DatePicker from '../DatePicker';
import MoneyInput from '../CustomInputs/MoneyInput';
import CustomSelect from '../CustomInputs/CustomSelect';
import CustomTextField from '../CustomInputs/CustomTextField';
import { TVA } from '@lba-dev/package.local-globals/comptaCalcules';
import {
  data as modes,
  E_CHQ,
} from '@lba-dev/package.local-globals/encaissementMode';
import { E_DEC } from '@lba-dev/package.local-globals/encaissementType';
import {
  PE_ENC,
  PE_PAY,
  PE_VRF
} from '@lba-dev/package.local-globals/paidStatusEncaissements';

const styles = {
  paper: {
    width: '100%',
    marginTop: '1%'
  },
  addLine: {
    float: 'right',
    color: blue[500],
    margin: '1%'
  },
  table: {
    overflowY: 'visible',
    backgroundColor: red[500],
  },
  tableCell: {
    padding: '10px',
    border: '1px solid #000000',
  },
  button: {
    width: '40px',
    height: '40px'
  },
  disabled: {
    pointerEvents: 'none'
  }
};

const mapStateToProps = ({ users, userId }) => ({ users, userId });

class Decaissement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      line: props.line,
      data: {},
      decaissements: new List()
    };
    this.getLine = this.getLine.bind(this);
    this.delLine = this.delLine.bind(this);
    this.addLine = this.addLine.bind(this);
    this.getCompenent = this.getCompenent.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.decaissements !== state.decaissements) {
      return ({
        line: props.line,
        data: props.data,
        decaissements: props.decaissements
      });
    }
    return null;
  }

  setData(index, path, value) {
    if (typeof this.props.setData === 'function') {
      let decaissements = this.props.decaissements.toJS();
      decaissements[index][path] = value;
      this.props.setData('decaissements', new List(decaissements));
    }
  }

  addMulti(i) {
    const tmp = this.state.decaissements.toJS();
    const { line, setData, userId } = this.props;
    tmp[i].multis.push(
      line({
        tva: tmp[i].tva,
        numChq: tmp[i].numChq,
        saisie: (tmp[i].saisie || new Date()),
        encaissement: tmp[i].encaissement,
        mode: tmp[i].mode,
        userId,
        id: tmp[i].id,
        motif: tmp[i].motif,
        type: E_DEC,
      })
    );
    setData('decaissements', new List(tmp));
  }

  updateLine(i, l, mIndex) {
    let line = this.state.decaissements.toJS();
    if (mIndex >= 0) {
      line[i].multis[mIndex] = l;
    } else {
      line[i] = l;
      if (line[i].multis) {
        line[i].multis.forEach((e) => {
          e.numChq = line[i].numChq;
        });
      }
    }
    this.props.setData('decaissements', new List(line));
  }

  addLine() {
    const { tva, data } = this.props;
    let line = this.state.decaissements.toJS();
    line.push(this.props.line({ tva, id: data.id, paidStatus: PE_ENC }));
    this.props.setData('decaissements', new List(line));
  }

  delLine(index, mIndex) {
    let tmp = this.state.decaissements.toJS();
    if (mIndex >= 0) {
      tmp[index].multis.splice(mIndex, 1);
    } else if (index >= 0) {
      this.props.deleteCaissement(tmp[index]);
      tmp.splice(index, 1);
    }
    this.props.setData('decaissements', new List(tmp));
  }


  getCompenent(line, index, mIndex = -1) {
    const {
      classes,
      justDisplay = false,
      users
    } = this.props;
    const user = line.userId && users.find(u => u._id === line.userId);
    const disabled = [PE_VRF, PE_PAY].includes(line.paidStatus)
      || justDisplay || line.status || line.isArchive;
    const disabledMulti =
      mIndex >= 0 || line.isArchive || line.status || justDisplay;
    return (
      <TableRow key={`${index} and ${mIndex}`}>
        <TableCell className={classes.tableCell}>
          <TextField
            variant="standard"
            type='string'
            onChange={(v) => {
              line.id = Number(v.target.value);
              this.updateLine(index, line, mIndex);
            }}
            fullWidth
            disabled={mIndex < 0}
            value={line.id} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography children={(user && user.login) || 'Automatique'} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <CustomTextField
            fullWidth
            type='string'
            value={line.numChq || ''}
            path={['']}
            setData={(p, v) => {
              line.numChq = v;
              this.updateLine(index, line, mIndex);
            }}
            disabled={disabled || line.decaissementAuto}
          />
        </TableCell>
        <TableCell className={classes.tableCell}
          style={{ pointerEvents: 'none' }}>
          <DatePicker
            disabled={true}
            style={{ width: '100%' }}
            withInput
            startDate={new Date(line.saisie)}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <DatePicker
            style={{ width: '100%' }}
            disabled={disabled}
            before
            withInput
            startDate={new Date(line.encaissement)}
            onChange={(v) => {
              line.encaissement = dayjs(v).toISOString();
              this.updateLine(index, line, mIndex);
            }}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <CustomSelect
            disabled={disabled || line.decaissementAuto}
            path={['mode']}
            value={line.mode}
            setData={(p, v) => this.setData(index, p, v)}
            fullWidth>
            {modes.map((e) => (
              <MenuItem key={e._id} value={e.value}>{e.name}</MenuItem>
            ))}
          </CustomSelect>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <MoneyInput
            type='number'
            disabled={disabled}
            defaultValue={line.montant}
            path={['montant']}
            fullWidth
            setData={(p, v) => {
              line.montant = v;
              this.updateLine(index, line, mIndex);
            }}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <CustomSelect
            disabled={disabled}
            path={['tva']}
            value={line.tva}
            setData={(p, v) => {
              line.tva = v;
              this.updateLine(index, line, mIndex);
            }}
            fullWidth>
            {TVA.map(e => (
              <MenuItem key={e.number} value={e.number}>
                  TVA {e.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <CustomSelect
            disabled={disabled || !!line._id}
            path={['motif']}
            value={line.motif}
            setData={(p, v) => {
              line.motif = v;
              this.updateLine(index, line, mIndex);
            }}
            fullWidth>
            {motifData.map(i =>
              (<MenuItem key={i._id} value={i._id}>{i.name}</MenuItem>))}
          </CustomSelect>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <CustomSelect
            disabled={disabled || !!line._id}
            path={['destinataire']}
            value={line.destinataire}
            setData={(p, v) => {
              line.destinataire = v;
              this.updateLine(index, line, mIndex);
            }}
            fullWidth>
            {destinataires.data.map(i => (
              <MenuItem key={i._id} value={i._id}>{i.name}</MenuItem>))}
          </CustomSelect>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Fab
            className={classes.button}
            disabled={disabledMulti || line.mode !== E_CHQ}
            onClick={() => this.addMulti(index)}
            children={<Add />}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Fab
            disabled={disabled}
            className={classes.button}
            onClick={() => this.delLine(index, mIndex)}
            children={<Delete />}
          />
        </TableCell>
      </TableRow>
    );
  }
  getLine() {
    const decaissements = (this.state.decaissements || new List());
    return decaissements.map((line, index) => {
      if (line.multis && line.multis.length) {
        return [
          this.getCompenent(line, index),
          line.multis.map((e, i) => this.getCompenent(e, index, i)),
        ];
      }
      return this.getCompenent(line, index);
    });
  }

  render() {
    const { classes, justDisplay } = this.props;
    return (
      <Paper className={classes.paper}>
        {!justDisplay && <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'>
          <Typography key={1} type="body1">
            DECAISSEMENTS
          </Typography>
          <Button key={2} className={classes.addLine}
            onClick={this.addLine}>
            Ajouter une ligne
          </Button>
        </Box>}
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {[
                { name: 'OS', props: { style: { minWidth: 100 } } },
                { name: 'Utilisateur' },
                { name: 'Label' },
                { name: 'Date de saisie', props: { style: { minWidth: 85 } } },
                { name: 'Date d\'envoi', props: { style: { minWidth: 85 } } },
                { name: 'Mode' },
                { name: 'Montant' },
                { name: 'Tx TVA' },
                { name: 'Motif' },
                { name: 'Pour qui ?' },
                { name: 'Multi' },
                { name: 'Supp' },
              ].map((e, i) =>
                <TableCell
                  key={i}
                  children={e.name}
                  {...e.props || {}}
                  className={classes.tableCell}
                />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.getLine()}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default connect(mapStateToProps)(withStyles(Decaissement, styles));
