import { Button } from '@mui/material';
import { red } from '@mui/material/colors';
import { Cached } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import { newVersionDialog } from '../../../actions/general';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/system';

const blinkerAnimation = keyframes`
  50% {
      color: ${red[300]};
      border-color: ${red[100]};
      background-color: ${red[100]};
    }
`;

const CustomButton = styled(Button)({
  marginRight: 32,
  animation: `${blinkerAnimation} 2s linear infinite`,
  backgroundColor: red[50],
  '&:hover': {
    backgroundColor: red[50],
  },
});

const NewVersionButton = () => {
  const [newVersion, setNewVersion] = useState(
    JSON.parse(localStorage.newVersion || '{}')
  );
  const [timerId, setTimerId] = useState(0);
  let channel = null;
  try {
    channel = new BroadcastChannel('v3');
  } catch (error) {
    channel = null;
  }

  const resetNewVersion = React.useCallback(() => {
    if (Object.keys(newVersion).length) {
      localStorage.removeItem('newVersion');
      newVersionDialog();
      return setTimeout(() => window.location.reload(true), 2000);
    }
  }, [newVersion]);

  const actionLocalStorage = () => {
    setNewVersion(JSON.parse(localStorage.newVersion || '{}'));
    return null;
  };

  useEffect(() => {
    window.addEventListener('storage', actionLocalStorage);
    window.addEventListener('beforeunload', resetNewVersion);
    setNewVersion(JSON.parse(localStorage.newVersion || '{}'));
    return () => {
      window.removeEventListener('storage', actionLocalStorage);
      window.removeEventListener('beforeunload', resetNewVersion);
      if (channel) {
        channel.close();
      }
      clearTimeout(timerId);
    };
  }, []);

  useEffect(() => {
    if (channel) {
      channel.addEventListener('message', (e) => {
        if (e.data.action === 'reload') {
          resetNewVersion();
        }
      });
    }
    return () => {
      if (channel) {
        channel.close();
      }
    };
  }, [resetNewVersion]);

  useEffect(() => {
    if (newVersion.status) {
      const diff = dayjs(newVersion.forceUpdate).diff(dayjs(), 'minute');
      clearTimeout(timerId);
      const timer = setTimeout(resetNewVersion, diff * 60 * 1000);
      setTimerId(timer);
    }
  }, [newVersion]);
  return (
    newVersion.status
      ? <CustomButton
        children="Nouvelle version"
        variant="outlined"
        endIcon={<Cached />}
        onClick={() => {
          if (channel) {
            channel.postMessage({ action: 'reload' });
          } else {
            resetNewVersion();
          }
        }}
      /> : ''
  );
};

export default NewVersionButton;
