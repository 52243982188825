import React, { PureComponent } from 'react';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import { GenTooltipIcon } from '../utils/IconsDisplay';
import { Timer, Pause } from '@mui/icons-material';
import { green, red, orange } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
export default class CountDown extends PureComponent {
  state = {
    timer: null,
    prevTimer: this.props.timer,
    countDown: 0,
    colorTimer: ''
  }

  count = 0;

  componentDidUpdate(prevProps) {
    if (this.props.timer !== prevProps.timer) {
      clearInterval(this.count);
      this.count = setInterval(this.showTimer, 1000);
    }
  }

  componentDidMount() {
    this.count = setInterval(this.showTimer, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.count);
  }

  colorTimer() {
    const timerCountdown = dayjs.utc(this.state.countDown).format('H');
    if (timerCountdown <= 0) {
      return this.setState({
        colorTimer: red
      });
    } else if ((timerCountdown >= 1) && (timerCountdown <= 5)) {
      return this.setState({
        colorTimer: orange
      });
    }
    return this.setState({
      colorTimer: green
    });
  }

  showTimer = () => {
    const { timer: timerP, demarchagePause } = this.props;
    const countDown = dayjs(timerP).diff(dayjs());
    this.setState({
      countDown
    });
    let timer;

    if (dayjs().isAfter(dayjs(demarchagePause))) {
      timer = dayjs.utc(
        dayjs(timerP).diff(dayjs(demarchagePause).add(-10, 'm'))
      ).format('HH:mm:ss');
    } else if (countDown <= 0) {
      clearInterval(this.count);
      timer = 'Délai dépassé';
      this.setState({
        countDown: 0
      });
    }
    else {
      timer = dayjs.utc(countDown).format('HH:mm:ss');
    }
    this.setState({ timer });
    this.colorTimer();
  }

  render() {
    const { children, timer: timerProps, demarchagePause } = this.props;
    const { timer, colorTimer } = this.state;

    return (
      <div>
        <Grid container wrap="nowrap" alignItems="center">
          {demarchagePause && dayjs().isAfter(dayjs(demarchagePause))
            ? <React.Fragment>
              <Grid item xs={2} container alignItems="center">
                <GenTooltipIcon
                  text={dayjs(timerProps).format('LLL')}
                  Icon={Pause}
                  color={colorTimer}
                />
              </Grid>
              <Grid item xs={3}>
                <Tooltip disableInteractive
                  title={dayjs.utc(dayjs(timerProps)
                    .diff(dayjs(demarchagePause)))
                    .format('HH:mm:ss')}
                  children={<span>Pause</span>}
                />
              </Grid>
            </React.Fragment>
            : <React.Fragment>
              <Grid item xs={2} container alignItems="center">
                <GenTooltipIcon
                  text={dayjs(timerProps).format('LLL')}
                  Icon={Timer}
                  color={colorTimer}
                />
              </Grid>
              <Grid item xs={6}>
                {timer}
              </Grid>
            </React.Fragment>}
          {!!children && <Grid item xs={4}>
            {children}
          </Grid>}
        </Grid>
      </div>
    );
  }
}
