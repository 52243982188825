import React from 'react';
import { Button, Chip, Typography } from '@mui/material';
import {
  HdrAuto,
  HomeOutlined, HomeRounded,
  MailOutlineOutlined,
  MailRounded,
  PersonOutlineOutlined, PersonRounded,
  SmsOutlined, SmsRounded,
  SpeakerNotesOutlined,
  SpeakerNotesRounded,
  Star, StarBorderOutlined,
  SupportAgentOutlined
} from '@mui/icons-material';

import {
  red, blue,
  teal,
  amber, deepOrange
} from '@mui/material/colors';

import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import getDataById from '@lba-dev/package.local-globals/getDataById';
import {
  REGTAG
} from '@lba-dev/package.local-globals/regexps';

import collections from '../../constants/collections';
import Money from '../../utils/Money';

let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

export const getColorWithIndex = (letters) => {
  const colors = [
    amber['A400'],
    blue['A700'],
    red['A200'],
    teal['A700'],
    deepOrange['A200'],
  ];
  const charIndex = letters.split('')
    .reduce((a, b) => a + characters.indexOf(b), 0);

  return colors[
    (charIndex + colors.length) % colors.length];
};

const genIcon = ({ Icon, ...props }) => <Icon {...props} />;

export const typeFromCollection = {
  'interventions': 'intervention',
  'artisans': 'artisan',
  'devis': 'devis',
};

export const smsTypes = [{
  title: 'Général',
  type: 'all',
  hidden: (user) => !permit(user, { key: 'chatAll' }),
  statusType: (e) => e.type === 'artisan' ? 'artisanAStatus' : e.type,
  subStatusType: (e) => e.type === 'artisan' && 'artisanSubStatus',
  icon: ({ selected, ...props }) =>
    genIcon({ Icon: selected ? HomeRounded : HomeOutlined, ...props }),
}, {
  title: 'Star',
  type: 'allPinned',
  icon: ({ selected, ...props }) =>
    genIcon({ Icon: selected ? Star : StarBorderOutlined, ...props }),
  subStatusType: (e) => e.type === 'artisan' && 'artisanSubStatus',
  statusType: e => e.type === 'artisan' ? 'artisanAStatus' : e.type,
}, {
  title: 'Intervention',
  type: 'artisan',
  icon: ({ selected, ...props }) =>
    genIcon({ Icon: selected ? SmsRounded : SmsOutlined, ...props }),
  statusType: () => 'artisanAStatus',
  subStatusType: () => 'artisanSubStatus',
  collection: collections.artisans,
}, {
  title: 'Client',
  type: 'intervention',
  icon: ({ selected, ...props }) =>
    genIcon({
      Icon: selected ? PersonRounded : PersonOutlineOutlined,
      ...props
    }),
  statusType: e => e.type,
  collection: collections.interventions,
}, {
  title: 'Tickets',
  type: 'ticket',
  icon: ({ selected, ...props }) =>
    genIcon({ Icon: selected ?
      SpeakerNotesRounded : SpeakerNotesOutlined, ...props }),
  statusType: () => 'intervention',
  collection: collections.interventions,
},
{
  title: 'Mails',
  type: 'mailReceived',
  icon: ({ selected, ...props }) =>
    genIcon({
      Icon: selected ? MailRounded : MailOutlineOutlined,
      ...props
    }),
  statusType: () => 'artisanSubStatus',
  subStatusType: () => 'artisanSubStatus',
  collection: collections.mailReceived,
}];

export const getPinned = (smsType, allPinned) =>
  smsType === 'allPinned' ? (allPinned || []) :
    (allPinned || []).filter((b) => {
      if (smsType === 'all') {
        return b.type === 'artisan';
      }
      if (smsType === 'intervention') {
        return ['intervention', 'devis'].includes(b.type);
      }
      return b.type === smsType;
    });

export const formatDate = (date) => {
  let formatEnvoi = ((dayjs(date).isSame(dayjs(), 'year') && `${
    dayjs(date).isBetween(dayjs().add(-6, 'day'), dayjs()) ?
      'ddd' : 'DD MMM'
  }`) || 'MM YYYY');

  return dayjs(date).isSame(dayjs(), 'day') ?
    dayjs(date).format('HH:mm')
    : dayjs(date).format(formatEnvoi);
};

export const genCategories = (elem) =>
  <Button
    key={elem.name}
    style={{
      backgroundColor: elem.color[elem.name === 'neutre' ? '600' : '500'],
      color: 'white',
      fontSize: 10,
      marginRight: 4,
      minWidth: 30,
      padding: '2px 8px'
    }}
    children={elem.shortName ? elem.shortName : elem.name}
  />;

export const genStatus = (statusData) => statusData ?
  <Chip
    label={statusData.name}
    size="small"
    variant='outlined'
    style={{
      borderColor: statusData.color[500],
      color: statusData.color[500],
      fontSize: 10,
      height: 16,
      marginRight: 2
    }}
  /> : '';

export function stringAvatar(name, index, obj = {}) {
  const { isChat, contactColor, sender, transmitter, isAuto } = obj;
  name = name.replace(/^([0-9]{4,6}) /g, '').replace('- ', '').trim();
  const alias = (((name?.split(' ')[0] || '')[0] || '') + (
    (name?.split(' ')[1] || '')[0] || ''));
  const color = contactColor || getColorWithIndex(alias.toLowerCase(), index);
  const autoMessage = transmitter === 'AUTO' || isAuto;
  const senderIcon = autoMessage ? <HdrAuto /> : <SupportAgentOutlined />;
  return {
    color,
    sx: {
      bgcolor: color,
      fontSize: 12,
      fontWeight: '700',
      width: isChat ? 32 : 28,
      height: isChat ? 32 : 28
    },
    children: sender ? senderIcon : alias?.toUpperCase(),
  };
}

export const genConversationInfo = (selected, typeData) => {
  const dataObject = selected.dataObject || {};
  const {
    client,
    firstname = client?.name || '',
    name = client?.firstname || selected.companyName,
    address = client?.address || [],
    id,
    subStatus,
    status,
  } = dataObject;
  const statusData =
    status && (getDataById[typeData.statusType(selected)] || {})[status];
  const categories = dataObject.categories || [dataObject.categorie];
  const catButtons = categories.map((e) => {
    const catData = e && getDataById.categories[e];
    return catData && genCategories(catData, true);
  });
  const isTicket = selected.type === 'ticket';
  if (selected.type === 'artisan') {
    const subStatusData = subStatus && typeData.subStatusType &&
      (getDataById[typeData.subStatusType(selected)] || {})[subStatus];
    const zipcodes =
      address && Array.isArray(address) &&
      [...new Set(
        address.map((e) => e && e.zipcode.toString().slice(0, 2))
      )].join(', ');

    return {
      text: `${(name || '').capitalize()
      } ${(firstname || '').capitalize()} | Dept ${zipcodes || ''}`,
      catButtons,
      statusChip: genStatus(statusData, selected.type),
      subStatusChip: genStatus(subStatusData, selected.type),
      logo: dataObject.logo
    };
  }
  return {
    text: id ? (
      <>
        {selected.type.capitalize()} n°
        <span style={{ cursor: 'pointer' }}>{id || ''}</span>
      </>
    ) : '',
    ticketClient: isTicket && <Typography
      style={{ display: 'inline-block', fontWeight: 'bold' }}
    >
      {`Client: ${client?.firstname || ''} ${firstname || ''}`}
    </Typography>,
    statusChip: genStatus(statusData, selected.type),
    catButtons,
    finalPrice: dataObject.finalPrice ? (
      <Typography
        style={{ display: 'inline-block', fontWeight: 'bold' }}
      >
        {Money.toString(dataObject.finalPrice, true)}
      </Typography>
    ) : '',
  };
};

export const handleLink = (link) => {
  window.open(link, '_blank');
};

const REGSPAN = new RegExp(/<span[^>]*>[^>]*<\/span>/g);
const REGDIV = new RegExp(/<div[^>]*>[^>]*<\/div>/g);

export const formatTextToHTML = (text = '') => {
  const splittedText = text.split('\n');
  const formatted = splittedText.reduce((r, e) => {
    const matched = [...e.matchAll(REGTAG)].map((a) => a[1]);
    r.push(matched.reduce((a, b) => {
      const data = b.split(';');
      return a.replace(
        `^#${b}#^`,
        `<span
        class="tagperson"
        contenteditable="false"
        data-tag="${data[0]};${data[1]}"
        style="color: #1a73e8;font-weight: bold">@${data[0]}</span>`
      );
    }, e));
    return r;
  }, []);
  return formatted.join('<br/>').replace('&nbsp;', ' ');
};

export const formatSpanToTag = (e) => {
  const allSpans = [...e.matchAll(REGSPAN)];
  const parser = new DOMParser();
  if (allSpans) {
    const nDocument =
      allSpans && parser.parseFromString(allSpans.join(','), 'text/html');
    const allHtmlSpans = Array.from(
      nDocument.querySelectorAll('span.tagperson')
    );

    const newVal = allHtmlSpans.reduce((a, b) => {
      const tag = b.dataset.tag;
      a = a.replace(b.outerHTML, `^#${tag}#^`);
      return a;
    }, e);
    return newVal.replace('&nbsp;', ' ')
      .replace(/<br\/>|<div><br\/><\/div>/g, '\n');
  }
  return e.replace('&nbsp;', ' ')
    .replace(/<br\/>|<div><br\/><\/div>/g, '\n');
};

export const removeDivs = (e) => {
  const allDivs = [...e.matchAll(REGDIV)];
  const parser = new DOMParser();
  if (allDivs) {
    const nDocument =
      allDivs && parser.parseFromString(allDivs.join(','), 'text/html');
    const allHtmlDivs = Array.from(
      nDocument.querySelectorAll('div')
    );
    const newVal = allHtmlDivs.reduce((a, b) => {
      a = a.replace(b.outerHTML, `\n${b.textContent}`);
      return a;
    }, e);
    return newVal;
  }
  return e;
};

export const formatHTMLToValue = (html) => {
  html = formatSpanToTag(html);
  return html;
};

export const handlePastEvent = (handleChange) => {
  const target = document.querySelector('div#editableinput');
  if (target) {
    target.addEventListener('paste', (event) => {
      event.preventDefault();

      let paste = (event.clipboardData || window.clipboardData)
        .getData('text');
      const selection = window.getSelection();
      if (!selection.rangeCount) {
        return;
      }
      selection.deleteFromDocument();
      selection.getRangeAt(0).insertNode(document.createTextNode(paste));
      selection.collapseToEnd();
      handleChange();
    });
  }
};

export const genSmsName = (data, type) => {
  const isClient = type !== 'artisan';
  const elem = !isClient ? data : data.client;
  const {
    civility = '', firstname = '', name = '',
    companyName = '', society = ''
  } = elem || {};
  let recipient = ((!isClient ? companyName :
    civility === 'Société' && society) || '').toUpperCase();
  if (firstname || name) {
    recipient += recipient ? ' - ' : '';
    recipient += name ? `${name.capitalize()}` : '';
    recipient += firstname ? ` ${firstname.capitalize()}` : '';
  }
  return isClient ? `${data.id} - ${recipient}` : recipient;
};

export const getContactTels = (data, isArtisan) => {
  const tels = [1, 2, 3]
    .reduce((acc, curr) => {
      if (isArtisan && data[`tel${curr}`]) {
        acc.push({
          title: `Téléphone Artisan ${curr}`,
          shortTitle: `Tel ${curr}`,
          tel: data[`tel${curr}`]
        });
      } else {
        if (data.client && data.client[`tel${curr}`]) {
          acc.push({
            title: `Téléphone Client ${curr}`,
            shortTitle: `Tel ${curr}`,
            tel: data.client[`tel${curr}`] });
        }
        if (!data.onSitePayment && data.billing && data.billing[`tel${curr}`]) {
          acc.push({
            title: `Téléphone Payeur ${curr}`,
            shortTitle: `Payeur tel ${curr}`,
            tel: data.billing[`tel${curr}`]
          });
        }
      }
      return acc;
    }, []);
  return (tels.length ? tels : (data.tels || []).map((e, i) => {
    const curr = i + 1;
    return {
      title: [`Téléphone ${isArtisan ? 'Artisan' : 'Client'} ${curr}`],
      shortTitle: `Tel ${curr}`,
      tel: e
    };
  })).filter(Boolean);
};
