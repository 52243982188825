import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import grey from '@mui/material/colors/grey';
import api from '../../api';
import notifSystem from '../../notifSystem';
import { getType, getLogin, getMomentOrDate } from '../../utils/function';
import {
  FACT_A,
  FACT_S,
  FACT_P,
  PACK_S
} from '@lba-dev/package.local-globals/historyCat';
import { openPreview } from '../../actions/general';
import store from '../../store';
import { setDialog } from '../../actions/dialog';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';

const style = {
  row: {
    width: '33%',
    backgroundColor: grey[200],
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
  },
  rowData: {
    width: '33%',
    textAlign: 'center',
  },
  btn: {
    color: 'inherit'
  },
  btnGrey: {
    color: grey[500]
  }
};

function HistoriqueEmpty() {
  return (
    <Grid>
      <Typography style={{ textAlign: 'center', padding: 10 }}>
        Aucun Historique
      </Typography>
    </Grid>
  );
}

export default class Dialogs extends Component {
  constructor() {
    super();
    this.state = {
      history: null,
      visible: true,
      open: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }

  async componentDidMount() {
    const history = await api.history
      .custom('getSpecificHistory')
      .post({
        id: this.props.defaultText._id,
        filter: [FACT_A, FACT_S, FACT_P, PACK_S],
      });
    this.setState({ history: history.body().map(e => e.data()) });
  }

  handleClose() {
    this.setState({ visible: false });
    if (typeof this.props.callback === 'function') {
      this.props.callback(false);
    }
    return false;
  }

  openFacturierPreview = () =>
    openPreview({
      method: 'facturier',
      info: { type: 'facturier', noCondition: true },
      data: [this.props.defaultText]
    }, 'Facturier');

  sendFacturier = (type) => {
    api
      .one('artisans', this.props.defaultText.id)
      .custom('sendFacturier')
      .post({
        type,
      })
      .then(() => this.openFacturierPreview())
      .catch(e => notifSystem.error(e.name, e.message));
  }

  handleSend(type) {
    const historyPack = this.state.history?.find(e => e.categorie === PACK_S);
    if (this.props.defaultText.id) {
      if (historyPack && historyPack.date && type === PACK_S) {
        store.dispatch(
          setDialog({
            name: 'CustomDialog',
            open: true,
            hideClose: true,
            dialogProps: {
              title: 'Un pack à déjà été envoyé le ' +
                  `${dayjs(historyPack.date).format('L [à] HH:mm')}, ` +
                  'voulez-vous vraiment renvoyer un pack ?',
              middle: true,
              middleAll: true,
            },
            contentProps: {
              content: null,
              boxProps: {
                width: '100%'
              }
            },
            actions: [
              {
                children: 'Non',
                color: 'secondary',
                onClick: (data, close) => {
                  close();
                }
              },
              {
                children: 'Oui',
                color: 'primary',
                onClick: (data, close) => {
                  this.sendFacturier(type);
                  close();
                }
              }
            ]
          })
        );
      } else {
        this.sendFacturier(type);
      }
    }
  }

  getRow() {
    const { history } = this.state;
    const data = history.map((e, i) => (
      <ListItem key={i}>
        <Grid style={style.rowData}>{getType(e.categorie)}</Grid>
        <Grid style={style.rowData}>{getMomentOrDate(e.date)}</Grid>
        <Grid style={style.rowData}>{getLogin(e.userId)}</Grid>
      </ListItem>
    ));
    return (
      <List>
        <ListItem>
          <Grid style={style.row}>Action</Grid>
          <Grid style={style.row}>Date</Grid>
          <Grid style={style.row}>Login</Grid>
        </ListItem>
        {data}
      </List>
    );
  }

  render() {
    const {
      closeText,
      buttonPrint,
      buttonEnvoiFacturier,
      buttonEnvoiPack,
      maxWidth,
      fullWidth,
    } = this.props;
    const { history } = this.state;
    const historyPack = this.state.history?.find(e => e.categorie === PACK_S);
    return (
      <div>
        <Dialog
          onEscapeKeyDown={this.handleClose}
          open={this.state.visible}
          maxWidth={maxWidth}
          fullWidth={fullWidth}
        >
          <DialogTitle>Historique Facturier</DialogTitle>
          <DialogContent>
            <List>
              {history && history.length ? (
                this.getRow()
              ) : (
                <HistoriqueEmpty props={this.props} />
              )}
            </List>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.openFacturierPreview()}
              color="secondary"
            >
              {buttonPrint}
            </Button>
            <Button onClick={() => this.handleSend(FACT_S)} color="inherit">
              {buttonEnvoiFacturier}
            </Button>
            <Button
              onClick={() => this.handleSend(PACK_S)}
              style={historyPack?.date ? style.btnGrey : style.btn}
            >
              {buttonEnvoiPack}
            </Button>
            <Button onClick={this.handleClose} color="primary">
              {closeText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Dialogs.defaultProps = {
  closeText: 'Fermer',
  buttonPrint: 'Imprimer',
  buttonEnvoiFacturier: 'Envoyer Facturier',
  buttonEnvoiPack: 'Envoyer Pack',
  maxWidth: 'sm',
  fullWidth: true,
};
