import React, { PureComponent } from 'react';
import { List, fromJS } from 'immutable';

import { withStyles } from 'tss-react/mui';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import {
  P_TYPE_RECALL, P_TYPE_ABS
} from '@lba-dev/package.local-globals/prospRememberStatus';

import {
  Categories,
  InfoArtisan,
  DetailActionsExtren,
  DetailCardHeader,
  Dialogs,
} from './index';
import api from '../../api';
import notifSystem from '../../notifSystem';
import DisplayNote from './DisplayNote';

const styles = theme => ({
  categories: {
    background: '#80808042',
    borderRadius: 5,
    width: '85%',
    margin: 'auto',
    align: 'center',
  },
  valider: {
    width: '100%',
    background: '#45a245',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: 200,
    left: 800,
  },
  cardScroll: {
    overflowY: 'auto',
    height: window.innerHeight - 200,
    borderRadius: '0px !important',
    boxShadow: 'none',
  },
  DetailAction: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  DetailActionB: {
    marginTop: 5,
    minWidth: 190
  },
  DetailActionLarge: {
    marginTop: 5,
    minWidth: 400
  },
  docs: {
    display: 'block',
    padding: '2px 10px'
  },
  padding: {
    padding: 16
  }
});

class DetailItemExtren extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      seeMore: false,
      hideDetail: true,
      open: false,
      time: '2019-05-24T18:00',
      openGalerie: false,
      openHist: false,
      openIfram: false,
      openSms: false,
      hist: [],
      _id: props.merge && props.merge.getIn(['elem', 'obj', '_id'])
    };
  }

  updateState = (name, value, cb = null) => {
    this.setState({ [name]: value }, cb);
  };

  changeTime = e => {
    this.setState({ time: e.target.value });
  };

  openDialog = () => {
    this.setState({ open: true, time: dayjs().format('YYYY-MM-DDT18:00') });
  };

  optionRappel = () => {
    const { merge, userId, setData } = this.props;
    const { time } = this.state;
    const text = 'Rappel pour un Prosp';
    const startDate = dayjs(time).format('YYYYMMDDTHHmmss');
    const endDate = dayjs(time)
      .add(15, 'minute')
      .format('YYYYMMDDTHHmmss');
    const url = `${window.location.origin}${
      window.location.pathname
    }?searchKey=${merge.getIn(['elem', 'obj', 'title'], '')}`;
    const details = `Rappeler l'artisan ${merge.getIn(
      ['elem', 'obj', 'title'],
      ''
    )} \n ${url}`;
    const googleCalendarUrl = `http://www.google.com/calendar/event?action=TEMPLATE&text=${text}&dates=${startDate}/${endDate}&details=${details}&location=Paris`;
    const reminders =
    merge.getIn(['elem', 'obj', 'reminders'], new List());
    this.setState({ open: false }, () => setData(
      ['elem', 'obj', 'reminders'],
      reminders.push(
        fromJS({
          userId,
          date: {
            reminder:
              dayjs(time).format(), creation: dayjs().format()
          },
          type: P_TYPE_RECALL
        })
      )
    ));

    window.open(googleCalendarUrl);
  };

  artisanMissing = () => {
    const { setData, userId, merge } = this.props;
    const { time } = this.state;
    const startDate = dayjs(time).toDate();
    const absences = merge.getIn(['elem', 'obj', 'reminders'], new List());
    this.setState({ open: false }, () => setData(['elem', 'obj', 'reminders'],
      absences.push(
        fromJS({
          userId,
          date: { reminder: startDate, creation: dayjs().format() },
          type: P_TYPE_ABS
        })
      )
    ));
  };

  showHist = async(merge) => {
    const prospId = merge.getIn(['elem', 'obj', '_id']);
    const dataHist = await api.history
      .getAll({ id: prospId, name: 'prospId' })
      .catch(() =>
        notifSystem.error('Erreur', 'Error lors du get sur history')
      );
    const dataAppels = await api.appels.getAll({
      query: JSON.stringify({ prospId })
    }).catch(() => notifSystem.error(
      'Erreur',
      'Les appels n\'ont pas pu être récupérés'
    ));
    const hist = dataHist.body().map(e => e.data());
    const appels = dataAppels.body().map(e => e.data())
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    this.setState({
      openHist: true,
      hist,
      appels
    });
  };

  showAppelWarn = async (e, phoneIndex) => {
    const prospId = this.props.merge.getIn(['elem', 'obj', '_id']);
    const dataAppels = await api.appels.getAll({
      query: JSON.stringify({
        date: { $gt: dayjs().startOf('day').toDate() },
        prospId }),
      field: JSON.stringify({ _id: 1 }),
      limit: 1
    }).catch(() => notifSystem.error(
      'Erreur',
      'Les appels n\'ont pas pu être récupérés'
    ));
    const appels = dataAppels.body().map(e => e.data());
    return appels && !!appels.length ? this.setState({
      openAppel: true,
      callObj: { e, phoneIndex }
    }) : this.props.callFunc(e, phoneIndex);
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.merge.getIn(['elem', 'obj', '_id'])
    !== prevState._id) {
      return {
        seeMore: false,
        _id: nextProps.merge.getIn(['elem', 'obj', '_id'])
      };
    }
    return null;
  }

  render() {
    const {
      updateState,
      classes,
      merge,
      validate,
      call,
      callFunc,
      edit,
      isAdmin,
      setData,
      removeTarget,
      statusItem,
      setStatus,
      askStatus,
      interId,
      setNote,
    } = this.props;

    const arrayTextField = () => {
      const ArrayTextField = [
        {
          path: ['elem', 'obj', 'nomRepresentant'],
          type: 'text',
          placeholder: 'Nom Représentant',
          disabled: false,
          width: 180,
        },
        {
          path: ['elem', 'obj', 'prenom'],
          type: 'text',
          placeholder: 'Prénom',
          disabled: false,
          width: 180,
        }
      ];
      merge.getIn(['elem', 'obj', 'tels'], new List()).forEach((d, i) =>
        ArrayTextField.push({
          checked: true,
          path: ['elem', 'obj', 'tels', i],
          width: 180,
          type: !call && !isAdmin ? 'password' : 'number',
          placeholder: `tel ${i} ...`,
          index: i,
          disabled: !call && !isAdmin,
        }));
      return ArrayTextField;
    };

    if (askStatus) {
      setStatus(undefined, undefined, this.openDialog);
    }
    const interObj = merge.getIn(['elem', 'obj', 'inters'], new List())
      .find(e => e.get('id') === interId);
    const duration = interObj && interObj.get('duration') &&
    dayjs.duration(interObj.get('duration'), 'second');

    return (
      !!merge.size && (
        <React.Fragment>
          <Dialogs
            state={this.state}
            merge={merge}
            updateState={this.updateState}
            optionRappel={this.optionRappel}
            artisanMissing={this.artisanMissing}
            changeTime={this.changeTime}
            callFunc={callFunc}
          />
          <DetailCardHeader
            edit={edit}
            removeTarget={removeTarget}
            merge={merge}
            validate={validate}
          />
          <Card className={classes.cardScroll}>
            <pre>
              <strong>
                {interObj
                  ? `  ${interObj
                    .get('distance', 0)
                    .toFixed(interObj.get('distance', 0) < 1 ? 1 : 0)} km`
                  : ''}
                {duration
                  ? `  (${duration.hours()} h ${duration.minutes()} min)`
                  : ''}
              </strong>
            </pre>
            <DetailActionsExtren
              openDialog={this.openDialog}
              updateState={this.updateState}
              updateStateP={updateState}
              setStatus={setStatus}
              classes={classes}
            />
            {statusItem && (
              <div style={{ textAlign: 'center', marginBottom: 15 }}>
                <Button
                  onClick={() =>
                    setStatus(undefined, undefined, this.openDialog)
                  }
                  variant="outlined"
                  style={{
                    color: statusItem.color[500],
                    borderColor: statusItem.color[500],
                  }}
                >
                  {statusItem.name}
                </Button>
              </div>
            )}
            {interObj && <DisplayNote
              setNote={setNote}
              interObj={interObj}
              validate={validate}
            />}
            <InfoArtisan
              isAdmin={isAdmin}
              merge={merge}
              setData={setData}
              call={call}
              callFunc={this.showAppelWarn}
              updateState={this.updateState}
              arrayTextField={arrayTextField}
            />

            <Categories
              merge={merge}
              setData={setData}
              path={['elem', 'obj', 'categorie']}
            />
          </Card>
        </React.Fragment>
      )
    );
  }
}

export default withStyles(DetailItemExtren, styles);
