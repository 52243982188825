/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import api from '../api';
import notifSystem from '../notifSystem';
import { throwIt } from '../utils/function';
import {
  createXlsxFile, blobType
} from '@lba-dev/package.local-globals/xlsxFunctions';
import collections from '../constants/collections';

const MAX_DOC = 10000;
const DISPLAY = 200;

export const createXLSX = (data, header) => {
  try {
    const title = `export_${dayjs().format('DD-MM-YYYY[_]HH:mm:ss')}`;
    const file = createXlsxFile({ data, header, title });
    const blob = new Blob([file], { type: blobType });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = `export_${
      dayjs().format('L[_]HH:mm:ss')}.xlsx`;
    a.click();
  } catch (err) {
    throwIt(err);
  }
};

export const genDataAndCSV = async (obj) => {
  const {
    data = [],
    total,
    page = 0,
    collection,
    query,
    header
  } = obj;
  try {
    if (data.length >= total) {
      await createXLSX(data, header);
      return true;
    }
    const newData = await api[collection]
      .custom('getCSV')
      .get({ ...query, page, display: DISPLAY })
      .then((res) =>
        res.body().reduce(
          (a, b) => {
            a = a.concat(b.data());
            return a;
          },
          [...data]
        )
      )
      .catch((e) => throwIt(e));
    if (!newData.length ||
    newData.length === data.length) {
      return throwIt('aucunes données trouvées');
    }
    return genDataAndCSV({ ...obj, data: newData, page: page + 1 });
  } catch (e) {
    throwIt(e);
  }
};

const convertFields = (fields) => fields.reduce((a, b) => {
  a[b] = 1;
  return a;
}, {});

export const formatHeaderAndFileFields = (
  originalFields, fileHeader, fields
) => ({
  [collections.interventions]: {
    fileHeader: fileHeader
      .insertAfterOrPush('Type de client', 'Ajout')
      .insertAfterOrPush('Acompte', 'Prix')
      .insertAfterOrPush('TVA', 'Acompte')
      .insertAfterOrPush('Sur place', 'TVA')
      .insertAfterOrPush('Recouvrement', ''),
    fileFields: originalFields
      .insertAfterOrPush('clientType', 'date.ajout')
      .insertAfterOrPush('products.advancePayment', 'announcedPrice')
      .insertAfterOrPush('products.tva', 'products.advancePayment')
      .insertAfterOrPush('onSitePayment', 'products.tva')
      .insertAfterOrPush('recovery.status', ''),
    fields: convertFields(Object.keys(fields)
      .insertAfterOrPush('billing.clientStatus', 'date.ajout')
      .insertAfterOrPush('products.advancePayment', 'announcedPrice')
      .insertAfterOrPush('products.tva', 'products.advancePayment')
      .insertAfterOrPush('onSitePayment', 'products.tva')
      .insertAfterOrPush('recovery.status', '')),
  },
  [collections.artisans]: {
    fields: convertFields(Object.keys(fields)
      .insertAfterOrPush('level', '')),
  },
  [collections.devis]: {
    fileHeader: fileHeader
      .insertAfterOrPush('Numero de tel', '')
      .insertAfterOrPush('Email Client', ''),
    fileFields: originalFields
      .insertAfterOrPush('client.tel1', '')
      .insertAfterOrPush('client.email1', ''),
    fields: convertFields(Object.keys(fields)
      .insertAfterOrPush('client.tel1', '')
      .insertAfterOrPush('client.email1', '')),
  }
});

export const exportList = async (collection, query, header) => {
  try {
    const total = await api[collection].getAll({
      ...query,
      call: 'countDocuments'
    }).then(e => e.body().data());
    if (total <= MAX_DOC) {
      await genDataAndCSV({
        total,
        query,
        collection,
        header
      });
    } else {
      throwIt('La limite d\'export est à 10000');
    }
  } catch (e) {
    notifSystem.error('erreur system', e.message || e);
  }
};
