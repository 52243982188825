import { dayjs } from '@lba-dev/package.local-globals/dayjs';

export const addressToString = (address) => {
  const roadNumber = address.number;
  const road = address.road;
  const zipcode = address.zipcode;
  const city = address.city;
  return `${roadNumber} ${road}, ${zipcode} ${city}`;
};
export const complementAdress = (client) => {
  const compl = client.compl ? `Complément d'adresse :\n${client.compl}` : '';
  const floor = client.floor ? `Etage : ${client.floor}, ` : '';
  const code = client.code ? `code : ${client.code}, ` : '';
  const building = client.building ? `bâtiment : ${client.building}` : '';
  let compl2 = floor || code || building ?
    `(${floor + code + building})\n` : '';
  return `${compl}\n${compl2}`;
};
export const clientToString = (client) => {
  const civility = client.civility || '';
  const name = client.name || '';
  const firstname = client.firstname ? ` ${client.firstname}` : '';
  if (client.civility === 'Société') {
    return `${civility}. ${client.society || ''}\n(${name}${firstname})`;
  }
  return `${civility}. ${name}${firstname}`;
};

export const dateToString = (date, flexHour, maxHour, definHClient) => {
  let string = `le ${dayjs(date).format('dddd DD MMMM YYYY [à] HH[h]mm')}`;
  if (flexHour) {
    string = `le ${dayjs(date).format('dddd DD MMMM YYYY [entre] HH[h]mm')
    } et ${dayjs(maxHour).format('HH[h]mm')}`;
  } else if (definHClient) {
    string = `le ${dayjs(date)
      .format('dddd DD MMMM YYYY[ (l\'heure à définir avec le client)]')}`;
  }
  return string;
};

export function getMomentOrDate(inter) {
  const date = new Date(inter.date.intervention) || '';
  const mDate = dayjs(date);
  const maxHour = inter.infoDesc.maxHour ?
    new Date(inter.infoDesc.maxHour) :
    dayjs(date.getTime()).add(2, 'hour').toDate();
  if (!date) {
    return;
  }
  if (inter.definDClient) {
    return '(date à définir avec le client)';
  }
  if (mDate > dayjs().add(1, 'day').startOf('day') &&
    mDate < dayjs().add(1, 'day').endOf('day')) {
    return dayjs(date).format(`[demain ]${
      inter.definHClient
        ? '[(horaire à définir avec le client)]'
        : '[à ]HH:mm'}`
    );
  }
  if ((mDate < dayjs().subtract(1, 'day')
    || mDate > dayjs().add(1, 'day')) || inter.flexHour
    || inter.definHClient)
  {
    return dateToString(date, inter.flexHour, maxHour, inter.definHClient);
  }
  dayjs.updateLocale('fr', {
    relativeTime: {
      s: 'quelques s',
      m: 'une minutes',
      mm: '%d minutes',
      h: '1h00',
      hh: '%dh00',
      d: 'un jour',
      dd: '%d jours',
    }
  });
  return dayjs(date).fromNow();
}

export const moneyToString = (money) => {
  //Precision will be lost if this limit is bypass
  if (money > 9007199254740991) {
    return '∞€';
  }
  const cent = money % 100;
  const euro = (money - cent) / 100;
  if (cent) {
    return `${euro}.${cent.toString().padStart(2, '0')} €`;
  }
  return `${euro} €`;
};


export function formatPrice(priceHt = 0, tva = 10, isTtc) {
  return moneyToString(
    (isTtc ? Math.round(priceHt * (1 + (tva / 100))) : priceHt).toFixed(2)
  );
}

export function getPrice(inter, isTtc = false, priceArtisan = false) {
  const tva = (inter.products || {}).tva || 10;

  if (priceArtisan) {
    return `${moneyToString(inter.priceArtisan)} \n`;
  }
  if (inter.flexPrice) {
    return `${inter.flexPrice ?
      `entre ${inter.announcedPrice ?
        formatPrice(inter.announcedPrice, tva, isTtc) : 0
      } et ${inter.announcedPriceMax ?
        formatPrice(
          inter.announcedPriceMax, tva, isTtc) : 0}  \n` :
      `${inter.announcedPrice ?
        formatPrice(inter.announcedPrice, tva, isTtc) :
        0}  \n`}`;
  }
  if (inter.announcedPrice){
    return `${formatPrice(inter.announcedPrice, tva, isTtc)} \n`;
  }
  if (inter.finalPrice) {
    return `${formatPrice(inter.finalPrice, tva, isTtc)} \n`;
  }
  return '0.00 €\n';
}

