import React from 'react';

import { setDialogContentProp } from '../../actions/dialog';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CustomCheckbox from '../CustomInputs/CustomCheckbox';
import { withStyles } from 'tss-react/mui';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';

const setDebriefProcess = setDialogContentProp('debriefProcess');

const styles = {
  caption: { marginLeft: 50 }
};

const populateObject = (debriefProcess) => {
  const initialKeys = [
    'firstInter', 'miseEnRelation', 'remuneration', 'missingDocs', 'supplier',
    'devisOnSite', 'devisSupp', 'paymentDelay', 'websitePro', 'sendCheque'
  ];
  return initialKeys.reduce((acc, curr) => ({
    ...acc,
    [curr]: debriefProcess[curr] ? debriefProcess[curr] : { checked: false }
  }), {});
};

const mapStateToProps = ({
  dialog: { contentProps: { debriefProcess, companyName } },
  users,
  userId,
}) => ({
  debriefProcess,
  companyName,
  user: users.find(u => u._id === userId)
});

const mapDispatchToProps = { setDebriefProcess };

const constants = () => [
  { name: 'Ressenti première intervention', key: 'firstInter' },
  { name: 'Contact client (mise en relation)', key: 'miseEnRelation' },
  {
    name: 'Rappel de la rémunération (pourcentage ou forfait)',
    key: 'remuneration'
  },
  { name: 'Rappels des documents manquants', key: 'missingDocs' },
  { name: 'Procédure fournisseur', key: 'supplier' },
  { name: 'Espace pro', key: 'websitePro' },
  { name: 'Devis / facture sur place', key: 'devisOnSite' },
  { name: 'Devis supplémentaire à distance', key: 'devisSupp' },
  { name: 'Délais de paiements (cb et chèque)', key: 'paymentDelay' },
  { name: 'Rappel de l\'envoi des chèques', key: 'sendCheque' },
];

const DebriefDialog = ({
  debriefProcess,
  companyName,
  setDebriefProcess,
  classes,
  user
}) => (
  <Grid>
    <Typography children={companyName} align="center" variant="subtitle2" />
    {constants(debriefProcess).map((e, i) => {
      const debriefObj = populateObject(debriefProcess)[e.key];
      return (
        <React.Fragment key={i}>
          <CustomCheckbox
            checked={debriefObj.checked}
            label={e.name}
            grid xs={12}
            path={e.key}
            setData={(p, v) => setDebriefProcess(
              populateObject({
                ...debriefProcess,
                [p]: {
                  checked: v,
                  ...(v
                    ? { date: new Date(), login: user.login }
                    : {})
                }
              })
            )}
          />
          {debriefObj.checked && debriefObj.date &&
            <Typography
              className={classes.caption}
              variant="caption"
              children={`Validé par ${debriefObj.login} le ${
                dayjs(debriefObj.date).format('L')}`}
            />}
        </React.Fragment>
      )}
    )}
  </Grid>
);

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(DebriefDialog, styles)
);

