import { dayjs } from '@lba-dev/package.local-globals/dayjs';

export const millisecond = 1;
export const second = 1000 * millisecond;
export const minute = 60 * second;
export const hour = 60 * minute;
export const day = 24 * hour;
export const year = 365 * day;

export const days = [
  'lun',
  'mar',
  'mer',
  'jeu',
  'ven',
  'sam',
  'dim'
];

export const months = [
  'jan',
  'fev',
  'mar',
  'avr',
  'mai',
  'juin',
  'juil',
  'aout',
  'sep',
  'oct',
  'nov',
  'dec'
];

export const monthsNumber = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12
];

export const monthsComplete = [
  'janvier',
  'février',
  'mars',
  'avril',
  'mai',
  'juin',
  'juillet',
  'août',
  'septembre',
  'octobre',
  'novembre',
  'décembre'
];

export const hours = [
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20
];

export const durations = [
  { name: 'Jour', label: 'day' },
  { name: 'Semaine', label: 'week' },
  { name: 'Mois', label: 'month' },
  { name: 'Année', label: 'year' },
];

export const todayToString = (date) => (
  `${days[date.getDay() ? date.getDay() - 1
    : date.getDay() + 6]}. ${date.getDate()} ${months[date.getMonth()]}.` +
  ` ${date.getFullYear()}`
);

export const monthToString = (date, monthDecal = 0) => {
  let m = date.getMonth() + monthDecal;
  const year = date.getFullYear() + Math.floor(m / 12);
  m = ((m < 0) ? 12 : 0) + (m % 12);
  return (`${months[m]}. ${year}`);
};

function formatLen(num) {
  return num > 9 ? num : `0${num}`;
}

export function stringToTime(time) {
  const hours = Number(time.substring(0, 2));
  const minutes = Number(time.substring(3, 5));

  return ((hours * 60) + minutes);
}

export function timeToString(time) {
  const hours = formatLen(Math.floor(time / 60));
  const minutes = formatLen(time % 60);
  return `${hours}:${minutes}`;
}
export function dateToString(date) {
  const day = formatLen(date.getDate());
  return `${day} ${monthsComplete[date.getMonth()]} ${date.getFullYear()}`;
}

export function shortDate(date) {
  const day = formatLen(date.getDate());
  return `${day}/${formatLen(date.getMonth() + 1)}/${date.getFullYear()}`;
}

export function getDate(date) {
  const day = formatLen(date.getDate());
  return `${date.getFullYear()}-${formatLen(date.getMonth() + 1)}-${day}`;
}

export function getTime(date) {
  return `${date.getHours().toString().padStart(2, '0')}:` +
    `${date.getMinutes().toString().padStart(2, '0')}`;
}

export function formatDate(date) {
  const format = date.toLocaleDateString('fr');
  return format === 'Invalid Date' ? '' : format;
}

export function formatCompleteDate(date) {
  const format = date.toLocaleString('fr');
  return format === 'Invalid Date' ? '' : format;
}

export function getYearFromNow(from) {
  const today = new Date().getFullYear() + 1;
  const allYear = [from];
  while (from < today) {
    from++;
    allYear.unshift(from);
  }
  return allYear;
}

export function generateTime(date, addHour = 2) {
  const time = date.getTime();
  return new Date(
    time + (addHour * hour) + (15 * minute) - (time % (15 * minute)));
}

export const formatDuration = (data, format = ['months', 'days', 'hours']) => {
  const time = dayjs.duration(data, 'millisecond');
  let text = [];
  if (format.includes('months') && time.month()) {
    text.push(`${time.month()} m`);
  }
  if (format.includes('days') && time.day()) {
    text.push(`${time.day()} j`);
  }
  if (format.includes('hour') && time.hour()) {
    text.push(`${time.hour()} h`);
  }
  if (format.includes('minutes') && time.minute()) {
    text.push(`${time.minute()} min`);
  }
  return data && text.join(' : ');
};

/*
  If date < 1 day return "il y a %s heures/"
  Else return DD/MM/YYYY
*/
export function getDateOrTime(date, width) {
  if (!date) {
    return;
  }

  if (
    dayjs(date) < dayjs().subtract(1, 'day') ||
    dayjs(date) > dayjs().add(1, 'day')
  ) {
    return width !== 'lg' && width !== 'xl'
      ? dayjs(date).format('DD/MM')
      : dayjs(date).format('DD/MM/YYYY');
  }
  if (
    dayjs(date).isAfter(dayjs().startOf('day')) &&
    dayjs(date).isBefore(dayjs())
  ) {
    return `${dayjs(date).format('HH[h]mm')}`;
  }
  return width !== 'lg' && width !== 'xl'
    ? `${dayjs(date).format('HH[h]mm')}`
    : dayjs(date).fromNow();
}
