import React, { Component } from 'react';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';

import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';

import MinCustomDialog from '../MinCustomDialog';
import notifSystem from '../../../notifSystem';
import { createGoogleCalendar } from '../../../actions/googleCalendar';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

const InputBox = ({ placeholder, handleChange, defaultValue }) =>
  <Input
    defaultValue={defaultValue}
    fullWidth
    placeholder={placeholder}
    onContextMenu={(e) => {
      e.stopPropagation();
    }}
    disableUnderline={true}
    onChange={e => handleChange(e.target.value)}
    multiline={true}
    rows={10}
  />;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  item: {
    padding: '10px',
  },
};

class RelanceDialog extends Component {
  state = {
    comment: this.props.defaultText.defaultValue || '',
    historyComment: '',
    date: dayjs().add('7', 'd').toDate(),
    loading: false
  }

  generateActions = (load) =>
    [
      {
        children: 'Fermer',
        color: 'secondary',
        onClick: this.props.callback.close
      },
      {
        children: 'Confirmer',
        color: 'primary',
        onClick: this.send
      }
    ].map((buttonProps, i) => {
      if (i === 1 && load) {
        return <CircularProgress key={i} size={24} />;
      }
      return <Button
        key={i}
        disabled={load}
        {...buttonProps}
      />;
    });

  send = async () => {
    const { historyComment, comment } = this.state;
    const { callback, defaultText } = this.props;
    let { date } = this.state;
    this.setState({ loading: true });
    if (!comment.trim() || !date || !historyComment.trim()) {
      this.setState({ loading: false });
      return notifSystem.error(
        'Relance',
        'Veuillez indiquer tous les champs demandés'
      );
    }
    await callback.send(comment, date.getTime(), historyComment)
      .then(() => {
        callback.close();
        notifSystem.success(
          'Relance',
          'La relance a bien été enregistrée'
        );
        setTimeout(() => createGoogleCalendar(
          date,
          defaultText.googleCalendarTitle,
          comment,
        ), 1000);
      })
      .catch(e => notifSystem.error('Relance', e.response.data || e.message))
      .finally(() => this.setState({ loading: false }));
    this.setState({ loading: false });
  }

  handleChange = field => value =>
    this.setState({
      [field]: value
    })

  render() {
    const {
      defaultText,
      classes,
      open
    } = this.props;
    const {
      date,
      loading,
      comment,
      historyComment,
    } = this.state;
    return (
      <MinCustomDialog
        middle
        maxWidth="md"
        open={open}
        title={defaultText.title}
        actions={this.generateActions(loading)}
      >
        <React.Fragment>
          <div className={classes.container}>
            <div className={classes.item}>
              <TextField
                variant="standard"
                label="Prochaine relance"
                defaultValue={
                  dayjs(date).format('YYYY-MM-DDTHH:mm')
                }
                type="datetime-local"
                onChange={event =>
                  this.handleChange('date')(new Date(event.target.value))} />
            </div>
            <div className={classes.item}>
              <InputBox
                placeholder="Text"
                defaultValue={comment}
                handleChange={this.handleChange('comment')}
              />
            </div>
            <Divider />
            <div className={classes.item}>
              <InputBox
                placeholder="Entrez votre commentaire..."
                value={historyComment}
                handleChange={this.handleChange('historyComment')}
              />
            </div>
          </div>
        </React.Fragment>
      </MinCustomDialog>
    );
  }
}

export default withStyles(RelanceDialog, styles);
