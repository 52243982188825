import React from 'react';

import { formatDate } from '@lba-dev/package.local-globals/dayjs';

import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import {
  L_ADVD,
  L_PAYD,
  L_PAYMULTIONEY,
  L_PAYART,
  L_PAYMULTIALMA,
} from '@lba-dev/package.local-globals/paymentCBActionTypes';

const TransactionsStatus = {
  [L_PAYD]: ({ transaction, user }) => (
    <DialogContent>
      <Typography align="center" variant="subtitle1">
        Une demande de paiement total a dèja été envoyée le{' '}
        {formatDate(transaction.date, 'L')} par{' '}
        {user ? user.login : 'Automatique'}
      </Typography>
      <Typography align="center" variant="subtitle1">
        Voulez-vous faire une nouvelle demande ?
      </Typography>
    </DialogContent>
  ),
  [L_ADVD]: ({ transaction, user }) => (
    <DialogContent>
      <Typography align="center" variant="subtitle1">
        Une demande d'acompte a dèja été envoyée le{' '}
        {formatDate(transaction.date, 'L')} par{' '}
        {user ? user.login : 'Automatique'}
      </Typography>
      <Typography align="center" variant="subtitle1">
        Voulez-vous faire une nouvelle demande ?
      </Typography>
    </DialogContent>
  ),
  [L_PAYART]: ({ user, transaction }) => (
    <DialogContent>
      <Typography align="center" variant="subtitle1">
        Une demande de paiement artisan a dèja été envoyée le{' '}
        {formatDate(transaction.date, 'L')} par{' '}
        {user ? user.login : 'Automatique'}
      </Typography>
      <Typography align="center" variant="subtitle1">
        Voulez-vous faire une nouvelle demande ?
      </Typography>
    </DialogContent>
  ),
  [L_PAYMULTIONEY]: ({ transaction, user }) => (
    <DialogContent>
      <Typography align="center" variant="subtitle1">
        Une demande de paiement total via Oney a dèja été envoyée le{' '}
        {formatDate(transaction.date, 'L')} par{' '}
        {user ? user.login : 'Automatique'}
      </Typography>
      <Typography align="center" variant="subtitle1">
        Voulez-vous faire une nouvelle demande ?
      </Typography>
    </DialogContent>
  ),
  [L_PAYMULTIALMA]: ({ transaction, user }) => (
    <DialogContent>
      <Typography align="center" variant="subtitle1">
        Une demande de paiement total via Alma a dèja été envoyée le{' '}
        {formatDate(transaction.date, 'L')} par{' '}
        {user ? user.login : 'Automatique'}
      </Typography>
      <Typography align="center" variant="subtitle1">
        Voulez-vous faire une nouvelle demande ?
      </Typography>
    </DialogContent>
  ),
};

class TransactionSended extends React.Component {
  state = {
    transactions: [],
  };

  render() {
    const {
      transaction: { type, login, url },
      users,
    } = this.props;
    const ComponentToDisplay = TransactionsStatus[type];
    const user = users.find((e) => e._id === login);
    return [
      <ComponentToDisplay
        key={1}
        user={user}
        {...this.props}
      />,
      <Typography align="center" component="p" key={2} variant='caption'>
        Pour acceder directement à la page de paiement cliquez{' '}
        <a href={url} rel="noopener noreferrer" target="_blank">
          ici
        </a>
      </Typography>
    ];
  }
}

export default TransactionSended;
