import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import notifSystem from '../../notifSystem';
import LineFormat from './LineFormat';
import UIList from '@mui/material/List';
import api from '../../api';
import { Types } from './ListInfo';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import AutoCompleteSearchBar from '../Utils/AutoCompleteSearchBar';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import StatsPanel from '../Stats/common/panel/StatsPanel';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import LinearProgress from '@mui/material/LinearProgress';
import {
  data as service
} from '@lba-dev/package.local-globals/services';
import { Close } from '@mui/icons-material';

const mapStateToProps = ({ users, userId }) => ({
  users,
  user: users.find((i) => i._id === userId),
});


class CallList extends Component {
  state = {
    mode: 'day',
    from: dayjs().startOf('month').toDate(),
    to: dayjs().endOf('day').toDate(),
    loading: false,
    dateSearch: false,
    calls: [],
    index: 0,
    count: null,
    serviceSelected: [],
    userSelected: [],
    allUsers: this.props.users.toJS(),
    moreThan40: false
  };

  loaded = false;
  timer;

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  load = async (artisanId, prospId = null, candidatId = null) => {
    try {
      let {
        index,
        count,
        from,
        to,
        userSelected,
        dateSearch,
        moreThan40
      } = this.state;


      const userIds = userSelected.map(item => item._id);

      let finds = [{ artisanId }];
      let filter = { };
      if (prospId) {
        finds.push({ prospId });
      }
      if (candidatId) {
        finds.push({ candidatId });
      }
      if (moreThan40) {
        filter.duration = { $gt: 40 };
      }
      if (userIds.length > 0) {
        filter.userId = { $in: userIds };
      }
      if (dateSearch) {
        filter.date = {
          $gt: dayjs(from).toDate(),
          $lt: dayjs(to).toDate()
        };
      }

      if (count === null) {
        count = await api.appels
          .getAll({
            query: JSON.stringify({
              $or: finds,
              ...filter
            }),
            call: 'countDocuments',
          })
          .then((res) => res.body().data());
      }
      const calls = await api.appels
        .getAll({
          query: JSON.stringify({
            $or: finds,
            ...filter
          }),
          sort: { date: -1 },
          limit: 10,
          page: index,
          display: 10,
        })
        .then((res) => res.body().map((e) => e.data()));

      return this.setState((state) => ({
        calls: state.calls.concat(calls),
        count,
      }), () => this.loadingData());

    } catch (error) {
      notifSystem.error('Erreur', 'Les appels n\'ont pas pu être récupérés');
      return this.setState({
        count: null,
      });
    }
  };

  handleMoreThan40Change = () => {
    const { artisanId, prospId, candidatId } = this.props;
    this.resetCalls();
    return this.setState({
      moreThan40: !this.state.moreThan40
    }, () => this.load(artisanId, prospId, candidatId));
  };

  loadingData = () => {
    this.timer = setTimeout(() => this.setState({ loading: false }), 2000);
  }

  getMore = () => {
    const { artisanId, prospId, candidatId } = this.props;
    this.setState(
      ({ index }) => ({
        index: index + 1,
      }),
      () => this.load(artisanId, prospId, candidatId)
    );
  };

  setComment = async (com, id) => {
    let calls = this.state.calls.slice(0);
    let callId = calls.findIndex((e) => e._id === id);
    let post = {
      appelId: id,
    };
    if (!calls[callId].locked) {
      post.comment = com.trim();
    }
    try {
      const res = await api.custom('appels/comment').post(post);
      if (callId >= 0) {
        calls[callId].locked = res.body().data();
        this.setState({
          calls,
        });
      }
      notifSystem.success('Commentaire', 'Le commentaire a bien été modifié');
    } catch (e) {
      notifSystem.error('Erreur', 'Le commentaire n\'a pas pu être modifié');
      throw e;
    }
  };

  resetCalls = () => this.setState({
    calls: [],
    count: null,
    loading: true
  });


  resetFilter = () => {
    const { artisanId, prospId, candidatId } = this.props;
    this.resetCalls();

    notifSystem.success('Message',
      'Vous venez de réinitialiser le filtre de la date');
    return this.setState({
      dateSearch: false,
    }, () => this.load(artisanId, prospId, candidatId));
  }

  handleDeleteServiceFilter = (id) => {
    const { artisanId, prospId, candidatId } = this.props;
    this.resetCalls();

    if (id) {
      return this.setState((state) => ({
        userSelected: state.userSelected.filter(user => user.service !== id),
        serviceSelected: state.serviceSelected
          .filter(service => service._id !== id),
        index: 0,
      }), () => this.load(artisanId, prospId, candidatId));
    }
  }

  handleDeleteUserFilter = (user) => {
    const { artisanId, prospId, candidatId } = this.props;
    const serviceExist = this.state.userSelected
      .some(u => u.service === user.service && u._id !== user._id);
    this.resetCalls();

    if (user._id) {
      return this.setState((state) => ({
        userSelected: state.userSelected.filter(u => u._id !== user._id),
        index: 0,
      }), () => {
        if (!serviceExist) {
          return this.handleDeleteServiceFilter(user.service);
        }
        this.load(artisanId, prospId, candidatId);
      });
    }
  }

  containsObject = (obj, list) => {
    let i;
    for (i = 0; i < list.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }
    return false;
  }

  selectAllUserOfService = (service) => {
    const { artisanId, prospId, candidatId } = this.props;

    const users = this.state.allUsers.filter((u) => u.service === service._id);
    this.setState((state) => ({
      userSelected: state.userSelected.concat(users.filter(u =>
        !this.containsObject(u, state.userSelected))),
      index: 0,
    }), () => this.load(artisanId, prospId, candidatId) );
  }

  handleOnAutoSuggestChange = (suggestedUserOrService) => {
    const { artisanId, prospId, candidatId } = this.props;

    if (!this.containsObject(suggestedUserOrService, this.state.serviceSelected)
      && this.containsObject(suggestedUserOrService, service)) {
      this.resetCalls();

      return this.setState((state) => ({
        serviceSelected: state.serviceSelected.concat(suggestedUserOrService),
        index: 0,
      }), () => this.selectAllUserOfService(suggestedUserOrService));
    }

    if (!this.containsObject(suggestedUserOrService, this.state.userSelected) &&
        !this.containsObject(suggestedUserOrService, service)) {
      this.resetCalls();

      return this.setState((state) => ({
        userSelected: state.userSelected.concat(suggestedUserOrService),
        index: 0,
      }), () => this.load(artisanId, prospId, candidatId));
    }
  }

  handleOnDatesChange = (from, to, mode) => {
    const { artisanId, prospId, candidatId } = this.props;
    this.resetCalls();
    return this.setState({
      dateSearch: true,
      from,
      to,
      mode,
      index: 0,
    }, () => this.load(artisanId, prospId, candidatId));
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.markerList !== this.props.markerList ||
      nextProps.artisanId !== this.props.artisanId
    ) {
      this.load(nextProps.artisanId, nextProps.prospId, nextProps.candidatId);
    }
  }

  render() {
    const { artisanId, prospId, candidatId } = this.props;
    const {
      calls,
      count,
      userSelected,
      serviceSelected,
      mode,
      from,
      to,
      dateSearch,
      loading,
      allUsers,
      moreThan40
    } = this.state;

    if (!this.loaded && artisanId) {
      this.loaded = true;
      this.load(artisanId, prospId, candidatId);
      return null;
    }
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12}>
          <Grid container style={{ alignItems: 'center' }}>
            <Grid item xs={3}>
              <AutoCompleteSearchBar
                placeholder="Rechercher un utilisateur ou un service"
                getSuggestions={(value) =>
                  new Promise((r) =>
                    r(
                      value.length > 1
                        ?
                        allUsers.concat(service).filter(
                          (u) =>
                            new RegExp(value, 'i').test(u.login) ||
                            new RegExp(value, 'i').test(u.name)
                        )
                        : '',
                    )
                  )
                }
                displaySuggestion={(suggestion) =>
                  `${suggestion.login || suggestion.name}`
                }
                onValidate={(suggestedUser) =>
                  this.handleOnAutoSuggestChange(suggestedUser)}
              />
            </Grid>
            <Grid item xs={3}>
              <span
                onClick={this.handleMoreThan40Change}>
                <VoicemailIcon color={moreThan40 ? 'success' : 'inherit'} />
              </span>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12} sx={{ mt: -3 }}>
                  <StatsPanel
                    onDatesChange={this.handleOnDatesChange}
                    defaultState={{
                      mode,
                      from,
                      to,
                    }}
                    displayModePanel={true}
                    displayDatesDropper={true}
                  >
                  </StatsPanel>
                </Grid>
                {
                  dateSearch &&
                    <Grid item xs={12}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        mr: -5 }}>
                        <Typography variant='body1'>
                          { from.toLocaleDateString('fr')
                              === to.toLocaleDateString('fr') ?
                            `Vous filtrez sur la date du
                                ${from.toLocaleDateString('fr')}`
                            :
                            `Vous filtrez 
                                entre le ${from.toLocaleDateString('fr')} 
                                et le ${to.toLocaleDateString('fr')}`
                          }
                        </Typography>
                        <IconButton aria-label="reset"
                          color='primary'
                          sx={{ mt: -1 }}
                          onClick={() => this.resetFilter()}
                        >
                          <RestartAltIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {
              serviceSelected &&
              serviceSelected.map((service) =>
                <Chip sx={{ margin: '3px' }}
                  key={service._id}
                  color='secondary'
                  label={`${service.name}`}
                  onClick={() => this.handleDeleteServiceFilter(service._id)}
                  onDelete={() => this.handleDeleteServiceFilter(service._id)}
                  deleteIcon={<Close />}
                />
              )
            }
          </Grid>
          <Grid item xs={12}>
            {
              userSelected &&
              userSelected.map((user) =>
                <Chip sx={{ margin: '3px' }}
                  key={user._id}
                  color='primary'
                  label={`${user.login} - ${service
                    .find(s => s._id === user.service).name}`}
                  onClick={() => this.handleDeleteUserFilter(user)}
                  onDelete={() => this.handleDeleteUserFilter(user)}
                  deleteIcon={<Close />}
                />
              )
            }
          </Grid>
          {
            !calls.length ?
              <Box>
                { !loading ?
                  <Typography
                    align='center'
                    variant='h5'
                    sx={{ padding: 5 }}>
                    {
                      userSelected.length > 0 || dateSearch ?
                        'Aucun appel n\'a été trouvé avec le filtre appliqué'
                        :
                        'Aucun appel n\'a été trouvé'
                    }
                  </Typography>
                  :
                  <LinearProgress sx={{ mt: 2 }} />
                }
              </Box>
              :
              <UIList sx={{ mt: 2 }}>
                {
                  calls &&
                      calls.map((e, id) => (
                        <LineFormat
                          setComment={this.setComment}
                          type={Types.Calls}
                          key={id}
                          info={e}
                        />
                      ))
                }
              </UIList>
          }
        </Grid>
        {
          calls.length < count && (
            <Grid item xs={2}>
              <Button onClick={this.getMore} variant="outlined">
                Voir plus
              </Button>
            </Grid>
          )
        }
      </Grid>
    );
  }
}

export default connect(mapStateToProps)(CallList);
