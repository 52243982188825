import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { RemoveRedEye, Sms as SmsIcon, Timer } from '@mui/icons-material';
import grey from '@mui/material/colors/grey';
import orange from '@mui/material/colors/orange';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import { data as formData } from '@lba-dev/package.local-globals/formProsp';
import {
  P_TYPE_RECALL,
} from '@lba-dev/package.local-globals/prospRememberStatus';
import { List } from 'immutable';

const GenTooltipIcon = ({ Icon, text, color, ...props }) => (
  <Tooltip disableInteractive
    title={<span>{text || ''}</span>}
    children={
      <Icon {...props} style={{ color: color[500], fontSize: 25, margin: 5 }} />
    }
  />
);
const IconsDisplay = {
  rappel: (elem, index) => elem.getIn(['obj', 'reminders'],
    new List()).find(e => e.get('type') === P_TYPE_RECALL
    && dayjs(e.getIn(['date', 'reminder'])).diff(dayjs()) > 0)
    && (<GenTooltipIcon
      Icon={Timer}
      key={index}
      color={orange}
      text={'Rappel en cours'}
    />),
  fromRp: (elem, index) => {
    const fromRp = formData
      .find(e => e._id === elem.getIn(['obj', 'formRp']));
    return fromRp && <GenTooltipIcon
      Icon={SmsIcon}
      key={index}
      color={fromRp.color}
      text={fromRp.text}
    />;
  },
  Sms: (elem, index) =>
    elem.getIn(['obj', 'sms', 'interesed']) !== undefined && (
      <GenTooltipIcon
        Icon={SmsIcon}
        key={index}
        color={grey}
        text={'envoyer un sms'}
      />
    ),
  seen: (elem, index) => elem.getIn(['obj', 'dates', 'sawForm']) && (
    <GenTooltipIcon
      Icon={RemoveRedEye}
      key={index}
      color={grey}
      text={dayjs(elem.getIn(['obj', 'dates', 'sawForm']))
        .format('LLL')}
    />
  )
};

export default IconsDisplay;
