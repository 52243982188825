import React from 'react';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import { setDialogContentProp } from '../../actions/dialog';
import Grid from '@mui/material/Grid';
import DatePicker from '../../components/DatePicker';
import CustomCheckbox from '../CustomInputs/CustomCheckbox';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';

const setPermanentAuthorization =
  setDialogContentProp('permanentAuthorization');
const setFrom = setDialogContentProp('from');
const setTo = setDialogContentProp('to');
const setOneDayOnly = setDialogContentProp('oneDayOnly');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      defaultText,
      permanentAuthorization,
      from,
      to,
      oneDayOnly,
    },
  },
}) => ({
  defaultText,
  permanentAuthorization,
  from,
  to,
  oneDayOnly,
});


const mapDispatchToProps = {
  setPermanentAuthorization,
  setFrom,
  setTo,
  setOneDayOnly
};

const DialogDistanceLogin = ({
  defaultText = { subtitle: '' },
  permanentAuthorization,
  from,
  to,
  setFrom,
  setTo,
  setPermanentAuthorization,
  setOneDayOnly,
  oneDayOnly,
}) => {
  const handleRange = (range) => {
    setFrom(range.from && dayjs(range.from).startOf('day').toDate());
    const to = oneDayOnly ? range.from : range.to;
    setTo(to && dayjs(to).endOf('day').toDate());
  };

  const handleDayOnly = (val) => {
    setOneDayOnly(val);
    if (val) {
      setTo(dayjs(from).endOf('day').toDate());
    }
  }
  return (<Grid container justifyContent="center">
    <Grid item xs={12}>
      {defaultText.contentText && defaultText.contentText.length
        ? <Typography
          align="center"
          variant="subtitle2"
          children={`${defaultText.contentText}`}
        />
        : ''}
    </Grid>
    <Grid item xs={12}
      style={{ marginTop: 15, display: 'flex', justifyContent: 'center' }}
    >
      <DatePicker
        mode={oneDayOnly ? 'single' : 'range'}
        selected={{ from, to }}
        handleRange={handleRange}
        onChange={(date) => oneDayOnly &&
          handleRange({ from: date.toDate() })}
        showWeekNumbers
        disabledDays={[
          { daysOfWeek: permanentAuthorization ? [...Array(7).keys()] : [0] },
          { before: new Date() }]}
        numberOfMonths={2}
      />
    </Grid>
    <CustomCheckbox
      grid
      xs={12}
      label={'Connexion Permanente'}
      disabled={!!oneDayOnly}
      checked={!!permanentAuthorization}
      path={'saturday'}
      setData={(_, v) => {
        if (v) {
          setFrom(null);
          setTo(null);
        }
        return setPermanentAuthorization(v);
      }}
    />
    <CustomCheckbox
      grid
      xs={12}
      label={'Un jour uniquement'}
      disabled={!!permanentAuthorization}
      checked={!!oneDayOnly}
      path={'oneDayOnly'}
      setData={(_, v) => handleDayOnly(v)}
    />
  </Grid>);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogDistanceLogin);
