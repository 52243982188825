import React, { PureComponent } from 'react';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';

import Grid from '@mui/material/Grid';

import { permit } from '@lba-dev/package.local-globals/restrictions';

import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';
import CustomCheckbox from '../CustomInputs/CustomCheckbox';

import { getTime } from '../../utils/date';
import { withDisplaying } from '../../hoc';
import { checkDailyBlocked } from '../../actions/verifications';

import CustomSelect from '../CustomInputs/CustomSelect';
import { MenuItem } from '@mui/material';
import {
  S_INT,
  S_DEVIS,
  S_LEAD,
} from '@lba-dev/package.local-globals/services';
import {
  NOT_CONFIRMED,
  SENIOR,
} from '@lba-dev/package.local-globals/permissions';

const path = {
  date: ['date', 'intervention'],
  dateSAV: ['date', 'savDate'],
  maxHour: ['infoDesc', 'maxHour'],
  modifDate: ['modifDate'],
};

const addTime = (date) => dayjs(date.getTime()).add(2, 'hour').toDate();

class DateSelector extends PureComponent {
  setTime = (e) => {
    const { setData, isSAV, date } = this.props;
    const numberPart = e.target.value.split(':');
    const newDate = this.props[e.target.name]
      ? new Date(this.props[e.target.name])
      : new Date(this.props.date);
    newDate.setHours(
      Number.parseInt(numberPart[0], 10),
      Number.parseInt(numberPart[1], 10),
      0,
      0
    );
    if (e.target.name === 'date') {
      setData(path[isSAV ? 'dateSAV' : 'date'], newDate);
    } else if (e.target.name === 'maxHour') {
      setData(
        path.maxHour,
        dayjs(date)
          .set({
            hour: newDate.getHours(),
            minute: newDate.getMinutes(),
          })
          .toDate()
      );
    }
  };

  setDate = (m) => {
    const { date, setData, modifDate, isSAV } = this.props;
    const newDate = new Date(date);
    newDate.setFullYear(m.year(), m.month(), m.date());
    if (!isSAV) {
      setData(path.modifDate, modifDate + 1);
    }
    if (isSAV && !date) {
      newDate.setHours(13);
    }
    setData(path[isSAV ? 'dateSAV' : 'date'], newDate);
  };

  setData = (p, v) => {
    const {
      setData,
      flexDate,
      flexHour,
      paths,
      definHClient,
      definDClient,
      date = new Date(),
    } = this.props;
    if (
      ((p[0] === 'definHClient' && flexHour) ||
        (p[0] === 'flexHour' && definHClient)) &&
      v
    ) {
      setData(flexHour ? paths.flexHour : paths.definHClient, false);
    }
    if (
      ((path[0] === 'definDClient' && flexDate) ||
        (path[0] === 'flexDate' && definDClient)) &&
      v
    ) {
      setData(flexDate ? paths.flexDate : paths.definDClient, false);
    }
    if (p[0] === 'flexHour' && v) {
      const maxHour = this.props.maxHour || new Date();
      setData(
        path.maxHour,
        dayjs(maxHour)
          .set({
            year: date.getFullYear(),
            month: date.getMonth(),
            date: date.getDate(),
            hour: date.getHours() + 2,
            minute: Math.trunc(date.getMinutes() / 15) * 15,
          })
          .toDate()
      );
    }
    if (p[0] === 'definHClient' && v) {
      setData(
        path.date,
        dayjs(date)
          .set({
            hour: 17,
            minute: 0,
            second: 0,
          })
          .toDate()
      );
    }
    return setData(p, v);
  };

  setInterventionDate = (date) => {
    const { setData, artisanBlocked, status } = this.props;
    if (checkDailyBlocked(status, artisanBlocked)) {
      setData(['artisan'], null);
    }
    return this.setDate(date);
  };

  render() {
    const {
      dateLabel,
      modifDate,
      user,
      flexHour,
      flexDate,
      definHClient,
      definDClient,
      paths,
      lg,
      xs,
      md,
      isSAV,
      date,
      maxHour,
      restrictions,
      setData,
      format = 'L'
    } = this.props;
    const hour = !isSAV && getTime(new Date(date));
    const hour2 = !isSAV && getTime(
      (maxHour && new Date(maxHour)) || addTime(date)
    );
    return [
      (
        [
          <Grid
            key="time"
            item
            xs={xs || 12}
            sm={6}
            md={md}
            lg={lg || 3}
            style={{ display: 'flex' }}
          >
            {isSAV ? <CustomSelect
              name="savDate"
              value={date ? dayjs(date).hour() : date}
              label={'Heure d\'intervention'}
              path={path.dateSAV}
              setData={(p, v) => setData(
                p, v
                  ? dayjs(date || undefined).hour(v).startOf('hour').toDate()
                  : date
              )}
            >
              {[
                { v: 0, name: 'Heure non défini', disabled: true },
                { v: 13, name: '08h et 13h', disabled: false },
                { v: 19, name: '13h et 19h', disabled: false }
              ].map((e, i) => (
                <MenuItem
                  key={i} value={e.v} children={e.name} disabled={e.disabled} />
              ))}
            </CustomSelect>
              : <TimePicker
                restrictions={restrictions}
                label={'Heure d\'intervention'}
                name="date"
                value={hour}
                onChange={this.setTime}
                labelRemoveSpace={true}
                size="100%"
                disabled={definHClient}
              />}
            {flexHour && !isSAV && (
              <TimePicker
                restrictions={restrictions}
                label={'  '}
                name="maxHour"
                value={hour2}
                onChange={this.setTime}
                size="90%"
                marginLeft="auto"
              />
            )}
          </Grid>,
          !isSAV ? (
            <Grid key="time2" container item sm={6} lg={3}>
              <CustomCheckbox
                grid
                xs={12}
                restrictions={restrictions}
                label="Horaire flexible +/- 2h"
                checked={flexHour}
                path={paths.flexHour}
                setData={this.setData}
              />
              <CustomCheckbox
                grid
                xs={12}
                restrictions={restrictions}
                label="A définir avec le client"
                disabled={!permit(user, { key: 'modifyDateADefinir' })}
                checked={definHClient}
                path={paths.definHClient}
                setData={this.setData}
              />
            </Grid>
          ) : (
            ''
          ),
        ]
      ),
      <Grid key="time3" item xs={xs || 6} sm={6} md={md} lg={lg || 3}>
        <DatePicker
          restrictions={restrictions}
          label={dateLabel}
          noBasicDate={isSAV && !date}
          before
          disabled={
            user &&
            [S_INT, S_DEVIS, S_LEAD].includes(user.service) &&
            !permit(user, { key: 'modifyDateSelector' }) &&
            ((user.permission <= NOT_CONFIRMED &&
              user.permission > SENIOR &&
              [S_INT, S_LEAD].includes(user.service) &&
              modifDate > 1) ||
              (user.permission <= SENIOR &&
                [S_INT, S_LEAD].includes(user.service) &&
                modifDate > 2) ||
              (user.service === S_DEVIS && modifDate > 2))
          }
          startDate={date}
          format={format}
          onChange={this.setInterventionDate}
        />
      </Grid>,
      permit(user, { key: 'modifyDateADefinir' }) && !isSAV ? (
        <Grid key="time4" container item sm={6} lg={3}>
          <CustomCheckbox
            grid
            xs={12}
            restrictions={restrictions}
            label="Date flexible +/- 1j"
            checked={flexDate}
            path={paths.flexDate}
            setData={this.setData}
          />
          <CustomCheckbox
            key="defH"
            grid
            xs={12}
            label="A définir avec le client"
            disabled={!permit(user, { key: 'modifyDateADefinir' })}
            restrictions={restrictions}
            checked={definDClient}
            path={paths.definDClient}
            setData={this.setData}
          />
        </Grid>
      ) : (
        ''
      ),
    ];
  }
}

DateSelector.displayName = 'DateSelector';

export default withDisplaying()(DateSelector);
