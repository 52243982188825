import React, { Component } from 'react';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { data as iStatusData } from '@lba-dev/package.local-globals/iStatus';
import { data as dStatusData } from '@lba-dev/package.local-globals/dStatus';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import blue from '@mui/material/colors/blue';

const statusArray = {
  devis: dStatusData,
  interventions: iStatusData,
};

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    textAlign: 'center',
    overflow: 'hidden',
  },
  button: {
    margin: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
    color: blue[500],
  },
});

const mapStateToProps = state => ({
  users: state.users,
});

class DialogGetClientNumber extends Component {
  handleClick = (confirm = false) => {
    if (typeof this.props.callback === 'function') {
      this.props.callback(confirm);
    }
  };

  render() {
    const {
      maxWidth,
      confirmBox,
      closeBox,
      defaultText,
      classes,
      users,
      open,
    } = this.props;
    const { data, name } = defaultText;
    return (
      <div className={classes.root}>
        <Dialog
          onEscapeKeyDown={() => this.handleClick(false)}
          open={open}
          maxWidth={maxWidth}
        >
          {name.map((e, i) =>
            data[e] && data[e].length ? (
              <React.Fragment key={i}>
                <DialogContent className={classes.content}>
                  <DialogTitle>
                    Liste des {e} ayant le même numéro de client
                  </DialogTitle>
                </DialogContent>
                <DialogContent>
                  <List>
                    {data[e].map(value => (
                      <ListItem
                        key={value.id}
                        dense
                        className={classes.listItem}
                      >
                        <ListItemText primary={`${e} n ${value.id}`} />
                        <ListItemText
                          primary={`Status ${value.status &&
                            statusArray[e].find(e => e._id === value.status)
                              .name}`}
                        />
                        <ListItemText
                          primary={`Ajouté le ${dayjs(value.date.ajout).format(
                            'L'
                          )} par ${users &&
                            users.find(e => e._id === value.login.ajout) &&
                            users.find(e => e._id === value.login.ajout)
                              .login}`}
                        />
                        <Button variant="outlined" color="primary">
                          <Link
                            className={classes.link}
                            target="_blank"
                            to={`/${e === 'interventions' ?
                              'intervention' : e}/${value.id}/`}
                          >
                            Voir {e}
                          </Link>
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                </DialogContent>
                {name.length === 1 && (
                  <DialogContent className={classes.content}>
                    <DialogTitle>Voulez-vous créer {e} ?</DialogTitle>
                  </DialogContent>
                )}
              </React.Fragment>
            ) : null
          )}

          <DialogActions>
            {name.length === 1 ? (
              [
                <Button key={0}
                  onClick={() => this.handleClick(true)}
                  color="primary">
                  {confirmBox}
                </Button>,
                <Button
                  key={1}
                  onClick={() => this.handleClick(false)}
                  color="secondary"
                >
                  {closeBox}
                </Button>,
              ]
            ) : (
              <Button onClick={() => this.handleClick(false)} color="primary">
                OK
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  withStyles(DialogGetClientNumber, styles)
);

DialogGetClientNumber.defaultProps = {
  maxWidth: 'sm',
  confirmBox: 'Oui',
  closeBox: 'Annuler',
};
