import React, { Component } from 'react';
import { fromJS, is } from 'immutable';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import {
  keySearch
} from '@lba-dev/package.local-globals/searchProsp';

import api from '../../api';
import notifSystem from '../../notifSystem';
import keys from '../../constants/keys';
import images from '../../utils/loadImages';

let mapkit = null;

/**
 * @param {string} color
 */
const getUrlAndColor = (color) => {
  if (color.startsWith('https://www.dropbox.com')) {
    return color
  }

  return images(color)
}

export default class AppleMap extends Component {
  constructor() {
    super();
    this.timeout = null;
    this.crefs = {
      map: null,
    };
    this.layers = [];
    this.map = null;
    this.marker = null;
  }

  /*
   ** Create MapKit MarkerAnnotation
   ** options: marker creation options
   ** color: name of image in front/public
   ** click: on marker click listener
   */
  createMarker = (
    {
      className = 'marker',
      map,
      position = new mapkit.Coordinate(46.1445302, 2.0474905),
      value,
      title = 'client',
      createElement,
    },
    color = '',
    click
  ) => {
    const el = document.createElement('div');
    el.key = value;
    el.title = title;
    if (createElement) {
      createElement(el);
    } else {
      const image = getUrlAndColor(color);
      el.style.backgroundImage = `url(${image})`;
      el.style.backgroundSize = 'contain';
    }
    el.style.width = '40px';
    el.style.height = '40px';
    el.style.backgroundRepeat = 'no-repeat';
    el.className = className;
    if (click && value) {
      el.addEventListener('click', click);
    }
    const annotation = new mapkit.Annotation(position, () => el, {
      title: title,
    });
    map.addAnnotation(annotation);
    return annotation;

  };

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js';
    script.async = true;
    script.type = 'text/javascript';
    document.head.appendChild(script);
    script.onload = () => {
      if (!window.mapkit) {
        return notifSystem.error('La map n\'a pas pu être chargée !');
      }
      mapkit = window.mapkit;
      mapkit.init({
        authorizationCallback: function(done) {
          done(keys.mapkit);
        },
        language: 'fr'
      });
      this.map = new mapkit.Map(this.crefs.map, {
        region: new mapkit.CoordinateRegion(
          new mapkit.Coordinate(46.1445302, 2.0474905),
          new mapkit.CoordinateSpan(0.5, 0.5)
        ),
        colorScheme: 'light',
        showsUserLocation: false,
        showsUserLocationControl: false,
        showsScale: mapkit.FeatureVisibility.Adaptive,
        cameraDistance: 5.5
      });

      const initialCoordinates = this.props.marker.latlng
        ? new mapkit.Coordinate(this.props.marker.latlng[0],
          this.props.marker.latlng[1])
        : new mapkit.Coordinate(46.1445302, 2.0474905);
      this.marker = this.createMarker(
        {
          map: this.map,
          position: initialCoordinates,
          title: this.props.marker.title,
        },
        this.props.marker.color
      );
      if (this.marker) {
        this.updatePlace([
          this.marker.coordinate.latitude,
          this.marker.coordinate.longitude
        ]);
      }

      if (this.props.RecherchePage) {
        this.map.addEventListener('region-change-end', this.setCenterEvent);
      }

      if (this.props.markerList) {
        this.updateMarkers(this.props.markerList, false);
        if (this.props.target) {
          this.displayRoute(this.props.markerList.find(
            elem => elem.getIn(['obj', 'value']) === this.props.target
          ));
        }
        if (this.props.cities) {
          this.updateCircleCities(this.props.cities);
        }
      }
    }
  }

  shouldComponentUpdate(props) {
    return (
      this.props.moveMarker !== props.moveMarker ||
      this.props.marker.latlng !== props.marker.latlng ||
      !is(this.props.markerList, props.markerList) ||
      this.props.target !== props.target ||
      props.cities !== this.props.cities
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.moveMarker !== prevProps.moveMarker) {
      this.moveTo(this.props.moveMarker);
    }
    if (this.props.marker.latlng !== prevProps.marker.latlng) {
      if (mapkit){
        this.updatePlace(this.props.marker.latlng);
      }
    }
    if (!is(this.props.markerList, prevProps.markerList)) {
      this.updateMarkers(this.props.markerList, true);
      if (this.props.target) {
        this.displayRoute(this.props.markerList.find(
          elem => elem.getIn(['obj', 'value']) === this.props.target
        ));
      }
    } else if (this.props.target !== prevProps.target) {
      this.displayRoute(this.props.markerList.find(
        elem => elem.getIn(['obj', 'value']) === this.props.target
      ));
    }
    if (prevProps.cities !== this.props.cities) {
      this.updateCircleCities(this.props.cities);
    }
  }


  setCenterEvent = () => {
    const { moveInMaps, marker } = this.props;
    if (moveInMaps) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      const center = this.map.region.center;
      if (!marker.latlng ||
        (marker.latlng[0] !== center.latitude
          || marker.latlng[1] !== center.longitude)) {
        this.timeout = window.setTimeout(() => {
          const newCenter = new mapkit.CoordinateRegion(
            new mapkit.Coordinate(marker.latlng[0], marker.latlng[1]),
            new mapkit.CoordinateSpan(0.01, 0.01)
          );
          this.map.setRegionAnimated(newCenter);
          this.props.updateData(
            'mapAddress',
            fromJS({
              location: {
                coordinates: [
                  newCenter.center.longitude,
                  newCenter.center.latitude
                ],
              },
            })
          );
        }, 3000);
      }
    }
  };

  updateMarkers(markers, deleteRef) {
    if (deleteRef) {
      this.deleteSource();
    }
    const markersArray = markers.toJS()
    if (!this.props.marker.latlng?.length && markersArray.length) {
      this.updatePlace([
        markersArray.at(-1).obj.coordinates.lat,
        markersArray.at(-1).obj.coordinates.lng
      ])
    }
    markersArray
      .filter(e => !e.hide)
      .forEach((elem, index) => {
        this.createMarker(
          {
            map: this.map,
            position: new mapkit.Coordinate(
              elem.obj.coordinates.lat,
              elem.obj.coordinates.lng
            ),
            title: elem.obj.title,
            value: elem.obj.value,
            createElement: elem.createElement
          },
          elem.color,
          this.props.RecherchePage
            ? () => elem.markerAction(index)
            : () => {
              if (!this.props.devisPage && this.props.markerAction) {
                this.props.markerAction(fromJS(elem));
              }
            }
        );
      });
  }

  updateCircleCities(cities) {
    const source = ({ index, city }) => ({
      coordinate: new mapkit.Coordinate(city.location.lat, city.location.lng),
      modelId: index,
    });
    this.deleteLayers();
    this.deleteSource();

    const newSources = cities.map((city, index) => source({ city, index }));

    newSources.forEach((citySource) => {
      const cityCoordinates = citySource.coordinate;

      const circleOverlay = new mapkit.CircleOverlay(cityCoordinates, 10000);
      circleOverlay.style = new mapkit.Style({
        lineWidth: 2,
        strokeColor: '#ff0000',
        fillColor: 'rgba(255, 0, 0, 0.3)',
      });

      this.map.addOverlay(circleOverlay);
    });
  }

  displayRoute(origin) {
    if (origin) {
      const destination = this.marker.coordinate;
      api.map
        .custom('direction')
        .get({
          query: JSON.stringify({
            destination: {
              lat: destination.latitude, lng: destination.longitude
            },
            origin: origin.getIn(['obj', 'coordinates']),
            travelMode: 'driving',
          }),
        })
        .then(response => {
          response = response.body().data();
          if (response.routes?.[0]) {
            const route = response.routes[0];
            if (this.props.setTargetInfo) {
              const duration = dayjs.duration(route.duration, 'seconds');
              const obj = {
                distance: `${Math.round(route.distance / 1000)} km`,
                duration: `${duration.hours()} h ${duration.minutes()} min`,
              };
              this.props.setTargetInfo(obj);
            }
            const lineStyle = new mapkit.Style({
              lineWidth: 5,
              strokeColor: '#0000FF'
            });
            const line = new mapkit.PolylineOverlay(
              response.routes[0].geometry.coordinates.map(coord =>
                new mapkit.Coordinate(coord[1], coord[0])
              ),
              {
                style: lineStyle
              }
            );
            this.deleteLayers();
            this.map.addOverlay(line);
          }
        })
        .catch(e => notifSystem.error(e.name, e.message));
    }
  }

  deleteLayers = () => {
    if (this.map.overlays.length) {
      this.map.removeOverlays(this.map.overlays);
    }
  }

  deleteSource = () => {
    if (this.map.annotations.length) {
      this.map.removeAnnotations(
        this.map.annotations.filter(e => e.title !== 'client')
      );
    }
  }

  updatePlace(latlng) {
    if (!latlng) {
      this.map.region = new mapkit.CoordinateRegion(
        new mapkit.Coordinate(46.1445302, 2.0474905),
        new mapkit.CoordinateSpan(0.5, 0.5)
      );
      this.deleteSource()
      return;
    }
    const loc = new mapkit.Coordinate(latlng[0], latlng[1]);
    this.map.region = new mapkit.CoordinateRegion(
      loc,
      new mapkit.CoordinateSpan(0.01, 0.01)
    );
    this.marker.coordinate = loc;
  }

  moveTo = ({ coordinates, type }) => {
    if (coordinates && type === keySearch) {
      const geoCoord = Array.isArray(coordinates) ? {
        lat: coordinates[1], lng: coordinates[0]
      } : {
        lat: coordinates.latitude ?? coordinates.lat,
        lng: coordinates.longitude ?? coordinates.lng
      };
      const loc = new mapkit.Coordinate(geoCoord.lng, geoCoord.lat);
      this.map.region = new mapkit.CoordinateRegion(
        loc,
        new mapkit.CoordinateSpan(0.01, 0.01)
      );
      this.marker.coordinate = loc;
    }
  };

  render() {
    const { width, height } = this.props;

    return (
      <div
        ref={el => {
          this.crefs.map = el;
        }}
        style={{
          width: width || '100%',
          height: height || '100%',
        }}
      ></div>
    );
  }
}
