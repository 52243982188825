/* eslint-disable max-lines */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import TableHead from '@mui/material/TableHead';
import {
  data as paidData,
  P_PAY,
  P_VRF,
  P_ENC,
  P_RIB,
} from '@lba-dev/package.local-globals/paidStatus';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Money from '../../utils/Money';
import MoneyInput from '../CustomInputs/MoneyInput';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import CustomSelect from '../CustomInputs/CustomSelect';
import store from '../../store';
import { dayjs } from '@lba-dev/package.local-globals/dayjs';
import { List } from 'immutable';
import {
  data as remStatus, R_POR, R_FOR
} from '@lba-dev/package.local-globals/remStatus';
import {
  setFourniture,
  paymentDefault,
  getArtisanRemun,
  getMontant,
} from '@lba-dev/package.local-globals/comptaCalcules';
import { deletePaiement, getPaiements } from '../../actions/paiements';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { permit } from '@lba-dev/package.local-globals/restrictions';
import { Delete } from '@mui/icons-material';
import notifSystem from '../../notifSystem';

const mapStateToProps = ({ users, userId }) => ({
  user: users.find(e => e._id === userId),
});

const deleteButton = ({
  allPayments, currentPayment, user, deleteCurrentPayment
}) =>
  permit(user, { key: 'annexeFlush' }) &&
  allPayments && allPayments[currentPayment] &&
  allPayments[currentPayment].status !== P_PAY &&
  allPayments[currentPayment]._id ?
    <Tooltip disableInteractive title="Supprimer le paiment">
      <IconButton
        style={{
          float: 'right', marginLeft: 5
        }}
        variant="contained"
        size="small"
        children={<Delete />}
        color="secondary"
        onClick={() => deleteCurrentPayment(
          allPayments[currentPayment].id)}
      />
    </Tooltip>
    : null;

const getPayementInformation = ({
  id,
  statusRemuneration,
  finalPrice,
  priceArtisan,
  infoDescSAV
}) => {
  if (!isNaN(id) && statusRemuneration === R_POR) {
    return { title: 'Prix final', price: finalPrice };
  } else if (!isNaN(id) && statusRemuneration !== R_POR) {
    return { title: 'Prix validé par l\'artisan', price: priceArtisan };
  }
  return {
    title: 'Prix validé par le service SAV',
    price: (infoDescSAV && infoDescSAV.SAVRemunPrice) || 0,
  };
};
class PaymentArtisan extends Component {
  state = {
    data: {},
    open: false,
    currentPayment: 0,
    allPayments: [],
  };
  static getDerivedStateFromProps(props) {
    return {
      data: props.data,
      statusRemuneration: props.statusRemuneration,
      open: props.open,
    };
  }

  componentDidMount() {
    const { paiements } = this.props;
    if (!paiements) {
      this.getData(this.props.data);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.save !== this.props.save) {
      this.updateFourniture(this.props.data);
      if (this.props.save) {
        this.save(this.state.data.id);
      }
      return true;
    }
  }
  setContent = (e) => {
    this.setState(
      {
        currentPayment: e.target.value,
      },
      () => this.updateFourniture(this.props.data)
    );
  }
  setData = (path, value) => {
    const { currentPayment, allPayments } = this.state;
    let data = allPayments;
    if (data[currentPayment] && data[currentPayment][path] !== value) {
      data[currentPayment][path] = value;
      this.setState({ allPayments: data });
    }
  }

  getData = async (data) => {
    try {
      const [artisan, paiements, reglements] = await getPaiements(data);
      const prix = reglements.length ? reglements[reglements.length - 1].ht : 0;
      const f = setFourniture(
        List.isList(data.supplies) ? data.supplies.toJS() : data.supplies
      );
      if (paiements && paiements.length) {
        let all =
        paiements.filter((e) => e.status === P_PAY).length === paiements.length
          ? [
            paymentDefault({
              rem: getArtisanRemun(data, artisan),
              bp: prix,
              f,
              finalPrice: data.finalPrice,
              categorie: data.categorie,
              loginAjoutIntervention: data.login.ajout
            }),
            ...paiements,
          ]
          : [...paiements];
        this.setState({
          allPayments: all,
          currentPayment: all.length - 1,
        });
      } else {
        this.setState({
          allPayments: [
            paymentDefault({
              rem: getArtisanRemun(data, artisan),
              bp: prix,
              f,
              finalPrice: data.finalPrice,
              categorie: data.categorie,
              loginAjoutIntervention: data.login.ajout
            }),
          ],
          currentPayment: 0,
        }, () => {
          if (isNaN(data.id)) {
            this.props.setRem({ target: { value: R_FOR } });
          }
        });
      }
    }
    catch (e){
      notifSystem.error('Erreur', 'Impossible de récupérer les paiements');
    }
    finally {
      if (this.props.finishLoading) {
        this.props.finishLoading();
      }
    }
  }

  updateFourniture = (data) => {
    const { allPayments, currentPayment } = this.state;
    if (
      allPayments[currentPayment] &&
      allPayments[currentPayment].status !== P_PAY
    ) {
      let all = allPayments;
      let f = setFourniture(
        List.isList(data.supplies) ? data.supplies.toJS() : data.supplies
      );
      all[currentPayment].fourniture = f.f;
      all[currentPayment].rFourniture = f.r;
      all[currentPayment].fournitureLba = f.lba;
      this.setState({
        allPayments: all,
      });
    }
  }

  save = (id) => {
    const { allPayments, currentPayment, statusRemuneration } = this.state;
    const {
      artisan: {
        remuneration: { pourcentage },
      },
    } = this.props;
    let content = allPayments[currentPayment];
    let legacy = allPayments
      .filter((e) => e.status === P_PAY)
      .reduce((t, p) => t + getMontant(p) + p.legacy, 0);
    const firstPayementDate = allPayments.filter((e) => e.status === P_PAY)
      .length
      ? {
        firstPayementDate: allPayments[0].flushDate,
        lastPayementDate: allPayments[allPayments.length - 1].flushDate,
      }
      : {};
    const obj = {
      bp: content.bp,
      tva: content.tva,
      dplcmt: content.dplcmt,
      mo: content.mo,
      fourniture: content.fourniture,
      rFourniture: content.rFourniture,
      fournitureLba: content.fournitureLba,
      mode: content.mode,
      artisanId: content.artisanId || this.state.data.artisan,
      id,
      status: content.status,
      finalPrice: content.finalPrice,
      categorie: content.categorie,
      loginAjoutIntervention: content.loginAjoutIntervention,
      remuneration: {
        status: statusRemuneration,
        ...pourcentage,
      },
      ...firstPayementDate,
    };
    if (allPayments.length > 1 && currentPayment === 0) {
      obj.lastPayment = getMontant(allPayments[allPayments.length - 1] || 0);
    }
    if (content._id) {
      obj.status = content.status === P_VRF ? P_ENC : content.status;
      obj.legacy =
        content.status === P_PAY ? content.legacy : Math.trunc(legacy * -1);
      this.props.sendPayement('patch', content._id, obj);
    } else {
      obj.legacy = Math.trunc(legacy * -1);
      this.props.sendPayement('post', obj);
    }
  }

  check = (event) => {
    const target = event.target.value;
    if (target !== undefined) {
      this.setState({
        [target]: !this.state[target],
      });
    }
  }

  setVentes = (content) => {
    let ventes = {
      dplcmt: 0,
      mo: 0,
    };
    if (content) {
      if (content.bp < 6500) {
        ventes.dplcmt = content.bp - content.fourniture;
        ventes.mo = content.fourniture;
      } else {
        ventes.dplcmt = 6500;
        ventes.mo = content.bp - 6500;
      }
    }
    return ventes;
  }

  deleteCurrentPayment = (id) =>
    deletePaiement(id).then(
      () => this.props.callback && this.props.callback(false)
    );

  render() {
    const {
      currentPayment,
      statusRemuneration: stateStatusRemuneration,
      data,
    } = this.state;
    const { justDisplay, paiements, user } = this.props;
    const allPayments = paiements || this.state.allPayments;
    if (!allPayments[currentPayment]) {
      return <div></div>;
    }
    let statusRemuneration =
      (allPayments[currentPayment] &&
        allPayments[currentPayment].status === P_PAY &&
        allPayments[currentPayment].remuneration.status) ||
      this.props.statusRemuneration ||
      stateStatusRemuneration;
    const content = allPayments[currentPayment];
    const ventes = this.setVentes(content);
    const ht = Math.floor((
      (ventes.mo - content.fourniture) * (content.mo / 100))
      + (ventes.dplcmt * (content.dplcmt / 100)) + content.rFourniture);
    const tvaT = ((ht * ((content.tva + 100) / 100)) - ht);
    const { title, price } = getPayementInformation({
      ...data,
      statusRemuneration,
    });
    return (
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item xs={6}>
          <Typography variant="subtitle1" children="Paiement Artisan" />
          <Typography variant="caption">
            ({title} : {Money.toString(price, true)})
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Select
            style={{ float: 'right', marginLeft: 5 }}
            value={currentPayment}
            onChange={this.setContent}
          >
            {allPayments.map((e, i) => {
              if (!e._id) {
                return (
                  <MenuItem key={i} value={i}>
                    Nouveau
                  </MenuItem>
                );
              }
              let name = '';
              let date = dayjs().format('DD/MM/YYYY');
              if (e.paid) {
                name = store
                  .getState()
                  .users.find((j) => j._id === e.paid.login)?.login;
                date = dayjs(e.paid.date).format('DD/MM/YYYY');
              }
              return (
                <MenuItem key={i} value={i}>
                  {name} ({date})
                </MenuItem>
              );
            })}
          </Select>
          <Select
            style={{ float: 'right' }}
            value={statusRemuneration}
            onChange={this.props.setRem}
            disabled={justDisplay || false}
          >
            {remStatus.map((e) => (
              <MenuItem key={e._id} value={e._id}>
                {e.name}
              </MenuItem>
            ))}
          </Select>
          {this.props.callback
            ? deleteButton({
              allPayments,
              currentPayment,
              user,
              deleteCurrentPayment: this.deleteCurrentPayment,
            })
            : null}
        </Grid>
        <Grid item xs={2}>
          <MoneyInput
            label="Base Paiement"
            type="number"
            disabled={justDisplay || (content && content.status === P_PAY)}
            defaultValue={content.bp}
            path={['bp']}
            setData={this.setData}
          />
        </Grid>
        <Grid item xs={2}>
          <CustomSelect
            path={['tva']}
            value={content.tva}
            disabled={justDisplay || (content && content.status === P_PAY)}
            label="TVA"
            setData={this.setData}
            fullWidth
          >
            <MenuItem value={0}>TVA 0%</MenuItem>
            <MenuItem value={20}>TVA 20%</MenuItem>
          </CustomSelect>
        </Grid>
        <Grid item xs={2}>
          <CustomSelect
            path={['mode']}
            value={content.mode}
            label="Mode"
            disabled={justDisplay || (content && content.status === P_PAY)}
            setData={this.setData}
            fullWidth
          >
            <MenuItem value={'cheque'}>Cheque</MenuItem>
            <MenuItem value={'virement'}>Virement</MenuItem>
          </CustomSelect>
        </Grid>
        <Grid item xs={4}>
          <CustomSelect
            path={['status']}
            value={content.status}
            label="Status"
            disabled={
              justDisplay ||
              (content && [P_PAY, P_VRF].includes(content.status))
            }
            setData={(p, v) =>
              ![P_PAY, P_VRF].includes(v) ? this.setData(p, v) : null
            }
            fullWidth
          >
            {paidData.map((e) => (
              <MenuItem
                key={e._id}
                value={e._id}
                disabled={[P_PAY, P_VRF, P_RIB].includes(e._id)}
              >
                {e.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </Grid>
        {statusRemuneration === R_POR ? (
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>MONTANT H.T. VENTE</TableCell>
                  <TableCell>{Money.toString(content.bp)}€</TableCell>
                  <TableCell>COMMISSION</TableCell>
                  <TableCell>REMUNERATION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Vente déplacement</TableCell>
                  <TableCell>{Money.toString(ventes.dplcmt)}€</TableCell>
                  <TableCell>
                    <Input
                      id="deplacement"
                      value={content.dplcmt}
                      style={{ width: '60px' }}
                      type="number"
                      disabled={
                        justDisplay || (content && content.status === P_PAY)
                      }
                      onChange={(event) =>
                        this.setData('dplcmt', parseInt(event.target.value, 10))
                      }
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {parseFloat(
                      Money.toString(ventes.dplcmt * (content.dplcmt / 100))
                    ).toFixed(2)}
                    €
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Vente M.O. & Fournitures</TableCell>
                  <TableCell>{Money.toString(ventes.mo)}€</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Coût fournitures</TableCell>
                  <TableCell>
                    -{' '}
                    {Money.toString(
                      content.fourniture ? content.fourniture : 0
                    )}
                    €
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Marge globale</TableCell>
                  <TableCell>
                    {Money.toString(ventes.mo - content.fourniture)}€
                  </TableCell>
                  <TableCell>
                    <Input
                      id="mo"
                      value={content.mo}
                      type="number"
                      disabled={
                        justDisplay || (content && content.status === P_PAY)
                      }
                      style={{ width: '60px' }}
                      onChange={(event) =>
                        this.setData('mo', parseInt(event.target.value, 10))
                      }
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {parseFloat(
                      Money.toString(
                        (ventes.mo - content.fourniture) * (content.mo / 100)
                      )
                    ).toFixed(2)}
                    €
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Remboursement Fournitures</TableCell>
                  <TableCell>{Money.toString(content.rFourniture)}€</TableCell>
                  <TableCell></TableCell>
                  <TableCell>{Money.toString(content.rFourniture)}€</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>TOTAL H.T.</TableCell>
                  <TableCell>
                    {parseFloat(Money.toString(ht)).toFixed(2)}€
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>TVA ({content.tva}%)</TableCell>
                  <TableCell>{Money.toString(tvaT)}€</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>TOTAL T.T.C.</TableCell>
                  <TableCell>
                    {parseFloat(Money.toString(ht + tvaT)).toFixed(2)}€
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        ) : null}
      </Grid>
    );
  }
}

export default connect(mapStateToProps)(PaymentArtisan);
